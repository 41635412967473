// Generated by PEG.js v0.11.0-master.0b102d2, https://pegjs.org/

function peg$subclass(child, parent) {
  function C() { this.constructor = child; }
  C.prototype = parent.prototype;
  child.prototype = new C();
}

function peg$SyntaxError(message, expected, found, location) {
  this.message = message;
  this.expected = expected;
  this.found = found;
  this.location = location;
  this.name = "SyntaxError";

  // istanbul ignore next
  if (typeof Error.captureStackTrace === "function") {
    Error.captureStackTrace(this, peg$SyntaxError);
  }
}

peg$subclass(peg$SyntaxError, Error);

peg$SyntaxError.buildMessage = function(expected, found) {
  var DESCRIBE_EXPECTATION_FNS = {
    literal: function(expectation) {
      return "\"" + literalEscape(expectation.text) + "\"";
    },

    class: function(expectation) {
      var escapedParts = expectation.parts.map(function(part) {
        return Array.isArray(part)
          ? classEscape(part[0]) + "-" + classEscape(part[1])
          : classEscape(part);
      });

      return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
    },

    any: function() {
      return "any character";
    },

    end: function() {
      return "end of input";
    },

    other: function(expectation) {
      return expectation.description;
    },

    not: function(expectation) {
      return "not " + describeExpectation(expectation.expected);
    }
  };

  function hex(ch) {
    return ch.charCodeAt(0).toString(16).toUpperCase();
  }

  function literalEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/"/g,  "\\\"")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function classEscape(s) {
    return s
      .replace(/\\/g, "\\\\")
      .replace(/\]/g, "\\]")
      .replace(/\^/g, "\\^")
      .replace(/-/g,  "\\-")
      .replace(/\0/g, "\\0")
      .replace(/\t/g, "\\t")
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/[\x00-\x0F]/g,          function(ch) { return "\\x0" + hex(ch); })
      .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return "\\x"  + hex(ch); });
  }

  function describeExpectation(expectation) {
    return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
  }

  function describeExpected(expected) {
    var descriptions = expected.map(describeExpectation);
    var i, j;

    descriptions.sort();

    if (descriptions.length > 0) {
      for (i = 1, j = 1; i < descriptions.length; i++) {
        if (descriptions[i - 1] !== descriptions[i]) {
          descriptions[j] = descriptions[i];
          j++;
        }
      }
      descriptions.length = j;
    }

    switch (descriptions.length) {
      case 1:
        return descriptions[0];

      case 2:
        return descriptions[0] + " or " + descriptions[1];

      default:
        return descriptions.slice(0, -1).join(", ")
          + ", or "
          + descriptions[descriptions.length - 1];
    }
  }

  function describeFound(found) {
    return found ? "\"" + literalEscape(found) + "\"" : "end of input";
  }

  return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};

function peg$parse(input, options) {
  options = options !== undefined ? options : {};

  var peg$FAILED = {};

  var peg$startRuleFunctions = { Program: peg$parseProgram };
  var peg$startRuleFunction = peg$parseProgram;

  var peg$c0 = ".segment";
  var peg$c1 = ".byte";
  var peg$c2 = ".word";
  var peg$c3 = ".string";
  var peg$c4 = ".append";
  var peg$c5 = ".const";
  var peg$c6 = ".import";
  var peg$c7 = ".namespace";
  var peg$c8 = ".macro";
  var peg$c9 = ".define";
  var peg$c10 = "{";
  var peg$c11 = "}";
  var peg$c12 = "add";
  var peg$c13 = "and";
  var peg$c14 = "brk";
  var peg$c15 = "br";
  var peg$c16 = "brs";
  var peg$c17 = "call";
  var peg$c18 = "calls";
  var peg$c19 = "clr";
  var peg$c20 = "cmp";
  var peg$c21 = "dec";
  var peg$c22 = "div";
  var peg$c23 = "enter";
  var peg$c24 = "exc";
  var peg$c25 = "exit";
  var peg$c26 = "halt";
  var peg$c27 = "ld";
  var peg$c28 = "loop";
  var peg$c29 = "loops";
  var peg$c30 = "in";
  var peg$c31 = "inc";
  var peg$c32 = "mod";
  var peg$c33 = "mov";
  var peg$c34 = "mul";
  var peg$c35 = "neg";
  var peg$c36 = "nop";
  var peg$c37 = "not";
  var peg$c38 = "or";
  var peg$c39 = "out";
  var peg$c40 = "pop";
  var peg$c41 = "popall";
  var peg$c42 = "popf";
  var peg$c43 = "push";
  var peg$c44 = "pushall";
  var peg$c45 = "pushf";
  var peg$c46 = "pushmm";
  var peg$c47 = "popmm";
  var peg$c48 = "ret";
  var peg$c49 = "sdiv";
  var peg$c50 = "set";
  var peg$c51 = "shl";
  var peg$c52 = "shr";
  var peg$c53 = "smod";
  var peg$c54 = "smul";
  var peg$c55 = "st";
  var peg$c56 = "sub";
  var peg$c57 = "swap";
  var peg$c58 = "test";
  var peg$c59 = "trap";
  var peg$c60 = "wait";
  var peg$c61 = "xor";
  var peg$c62 = "fclr";
  var peg$c63 = "fldr";
  var peg$c64 = "fldm";
  var peg$c65 = "fldim";
  var peg$c66 = "fstr";
  var peg$c67 = "fstm";
  var peg$c68 = "fstim";
  var peg$c69 = "fadd";
  var peg$c70 = "fsub";
  var peg$c71 = "fcmp";
  var peg$c72 = "fmul";
  var peg$c73 = "fdiv";
  var peg$c74 = "fmod";
  var peg$c75 = "fpow";
  var peg$c76 = "fsqrt";
  var peg$c77 = "fabs";
  var peg$c78 = "fsin";
  var peg$c79 = "fcos";
  var peg$c80 = "ftan";
  var peg$c81 = "fasin";
  var peg$c82 = "facos";
  var peg$c83 = "fatan";
  var peg$c84 = "fneg";
  var peg$c85 = "fexc";
  var peg$c86 = "fint";
  var peg$c87 = "fisnan";
  var peg$c88 = "fisinf";
  var peg$c89 = "flog2";
  var peg$c90 = "flog10";
  var peg$c91 = "fld0";
  var peg$c92 = "fld1";
  var peg$c93 = "flde";
  var peg$c94 = "fldpi";
  var peg$c95 = "if";
  var peg$c96 = "else";
  var peg$c97 = "do";
  var peg$c98 = "while";
  var peg$c99 = "break";
  var peg$c100 = "continue";
  var peg$c101 = "addrbank";
  var peg$c102 = "addrbh";
  var peg$c103 = "addrbofs";
  var peg$c104 = "addrbl";
  var peg$c105 = "addrpage";
  var peg$c106 = "addrph";
  var peg$c107 = "addrpofs";
  var peg$c108 = "addrpl";
  var peg$c109 = "asc";
  var peg$c110 = "next";
  var peg$c111 = "a";
  var peg$c112 = "al";
  var peg$c113 = "b";
  var peg$c114 = "bl";
  var peg$c115 = "c";
  var peg$c116 = "cl";
  var peg$c117 = "d";
  var peg$c118 = "dl";
  var peg$c119 = "x";
  var peg$c120 = "xl";
  var peg$c121 = "y";
  var peg$c122 = "yl";
  var peg$c123 = "sp";
  var peg$c124 = "bp";
  var peg$c125 = "pc";
  var peg$c126 = "flags";
  var peg$c127 = "z";
  var peg$c128 = "flag:z";
  var peg$c129 = "flag:c";
  var peg$c130 = "v";
  var peg$c131 = "flag:v";
  var peg$c132 = "n";
  var peg$c133 = "flag:n";
  var peg$c134 = "flag:x";
  var peg$c135 = "ex";
  var peg$c136 = "flag:ex";
  var peg$c137 = "id";
  var peg$c138 = "flag:id";
  var peg$c139 = "is";
  var peg$c140 = "flag:is";
  var peg$c141 = "ss";
  var peg$c142 = "flag:ss";
  var peg$c143 = "!";
  var peg$c144 = ".lte";
  var peg$c145 = ".ngt";
  var peg$c146 = ".lt";
  var peg$c147 = ".ngte";
  var peg$c148 = ".gte";
  var peg$c149 = ".nlt";
  var peg$c150 = ".gt";
  var peg$c151 = ".nlte";
  var peg$c152 = ".abe";
  var peg$c153 = ".nblo";
  var peg$c154 = ".abv";
  var peg$c155 = ".nble";
  var peg$c156 = ".ble";
  var peg$c157 = ".nabv";
  var peg$c158 = ".blo";
  var peg$c159 = ".nabe";
  var peg$c160 = ".eq";
  var peg$c161 = ".neq";
  var peg$c162 = "+";
  var peg$c163 = "-";
  var peg$c164 = "*";
  var peg$c165 = "/";
  var peg$c166 = "%";
  var peg$c167 = "<<";
  var peg$c168 = ">>";
  var peg$c169 = "&";
  var peg$c170 = "|";
  var peg$c171 = "^";
  var peg$c172 = "<=";
  var peg$c173 = ":=";
  var peg$c174 = ":";
  var peg$c175 = "_";
  var peg$c176 = "0x";
  var peg$c177 = "$";
  var peg$c178 = "0b";
  var peg$c179 = "[";
  var peg$c180 = "]";
  var peg$c181 = "(";
  var peg$c182 = ")";
  var peg$c183 = "<";
  var peg$c184 = ">";
  var peg$c185 = "\"";
  var peg$c186 = ",";
  var peg$c187 = "#";

  var peg$r0 = /^[A-Za-z]/;
  var peg$r1 = /^[_A-Za-z0-9\-.]/;
  var peg$r2 = /^[0-9A-Fa-f]/;
  var peg$r3 = /^[0-9A-Fa-f_]/;
  var peg$r4 = /^[0-1]/;
  var peg$r5 = /^[0-1_]/;
  var peg$r6 = /^[0-9]/;
  var peg$r7 = /^[0-9_]/;
  var peg$r8 = /^[ \t]/;
  var peg$r9 = /^[^\n]/;
  var peg$r10 = /^[\r\n]/;

  var peg$e0 = peg$otherExpectation("Program");

  var peg$f0 = function(parts) { return parts.filter(part => part !== null); };
  var peg$f1 = function(l) { return l; };
  var peg$f2 = function() { return null; };
  var peg$f3 = function(lines) { return tBlock(lines.flat().filter(line => !!line)); };
  var peg$f4 = function(label, content, c) { return [label, content, c].filter(e => e !== null); };
  var peg$f5 = function(e) { return e; };
  var peg$f6 = function() {
      return tInstruction("br", {
          flag: null,
          addr: addressingMode({ addr: tIdentifier("__end"), m: 0 })
      });
  };
  var peg$f7 = function() {
      return tInstruction("br", {
          flag: null,
          addr: addressingMode({ addr: tIdentifier("__begin"), m: 0 })
      });
  };
  var peg$f8 = function(f, t, e) {
      newScope();
      const THEN = uniqIdent("__then");
      const ELSE = uniqIdent("__else");
      const ENDIF = uniqIdent("__endif");
      const ast = tBlock([
          tInstruction("br", {
              [f.type === "flag" ? "flag" : "condition"]: f,
              addr: addressingMode({ addr: tIdentifier(THEN), m: 0 })
          }),
          tInstruction("br", {
              flag: null,
              addr: addressingMode({ addr: e ? tIdentifier(ELSE) : tIdentifier(ENDIF), m: 0 })
          }),
          tLabel(tIdentifier(THEN)),
          rewriteIdents(t),
          ...(e ? [
              tInstruction("br", {
                  flag: null,
                  addr: addressingMode({ addr: tIdentifier(ENDIF), m: 0 })
              }),
              tLabel(tIdentifier(ELSE)),
              rewriteIdents(e)
          ] : []),
          tLabel(tIdentifier(ENDIF))
      ]);
      exitScope();
      return ast;
  };
  var peg$f9 = function(l, f) {
      newScope();
      const BEGIN = uniqIdent("__begin");
      const END = uniqIdent("__end");
      const ast = tBlock([
          tLabel(tIdentifier(BEGIN)),
          rewriteIdents(l),
          tInstruction("br", {
              [f.type==="flag" ? "flag" : "condition"]: f,
              addr: addressingMode({ addr: tIdentifier(BEGIN), m: 0 })
          }),
          tLabel(tIdentifier(END)),
      ]);
      exitScope();
      return ast;
  };
  var peg$f10 = function(f, l) {
      newScope();
      const BEGIN = uniqIdent("__begin");
      const END = uniqIdent("__end");
      const DO = uniqIdent("__do");
      const ast = tBlock([
          tLabel(tIdentifier(BEGIN)),
          tInstruction("br", {
              [f.type==="flag" ? "flag" : "condition"]: f,
              addr: addressingMode({ addr: tIdentifier(DO), m: 0 })
          }),
          tInstruction("br", {
              flag: null,
              addr: addressingMode({ addr: tIdentifier(END), m: 0 })
          }),
          tLabel(tIdentifier(DO)),
          rewriteIdents(l),
          tInstruction("br", {
              flag: null,
              addr: addressingMode({ addr: tIdentifier(BEGIN), m: 0 })
          }),
          tLabel(tIdentifier(END)),
      ]);
      exitScope();
      return ast;
  };
  var peg$f11 = function(h, t) {
      return [h, ...t.map(([,,a,]) => a)].filter(n => n !== null);
  };
  var peg$f12 = function(name, args) {
      return tMacroExpansion(name, args);
  };
  var peg$f13 = function(name, addr, append, block) {
      return tSegment(name, addr, !!append, block);
  };
  var peg$f14 = function(name, block) {
      return tNamespace(name, block);
  };
  var peg$f15 = function(name, expr) { return tConstant(name, expr); };
  var peg$f16 = function(path) { return tImport(path); };
  var peg$f17 = function(size, data) { return tDataDirective(TOKENS.BYTE_DIRECTIVE, data, size); };
  var peg$f18 = function(data) { return tDataDirective(TOKENS.BYTE_DIRECTIVE, data); };
  var peg$f19 = function(size, data) { return tDataDirective(TOKENS.WORD_DIRECTIVE, data, size); };
  var peg$f20 = function(data) { return tDataDirective(TOKENS.WORD_DIRECTIVE, data); };
  var peg$f21 = function(data) {
      return tDataDirective(TOKENS.STRING_DIRECTIVE, data);
  };
  var peg$f22 = function(name, params, ast) {
  	return tMacroDirective(name, params, ast);
  };
  var peg$f23 = function(size) { return size; };
  var peg$f24 = function(bytes) {
      return bytes.map(([, byte]) => byte);
  };
  var peg$f25 = function(ins, bytes) {
      if (bytes) {
          ins.bytes = bytes;
      }
      return ins;
  };
  var peg$f26 = function(op) { return tInstruction(op); };
  var peg$f27 = function() { expectedRegister() };
  var peg$f28 = function() { expectedComma() };
  var peg$f29 = function() { expectedMemoryAddress() };
  var peg$f30 = function(op, dest, source) { return tInstruction(op, {dest, source}); };
  var peg$f31 = function(dest, source) { return tInstruction(OPCODES.LD, {dest, source}); };
  var peg$f32 = function(dest, source) { return tInstruction(OPCODES.ST, {dest, source}); };
  var peg$f33 = function(op, addr, reg) { return tInstruction(op, {addr, reg}); };
  var peg$f34 = function(op, flag, addr) { return tInstruction(op, {addr, flag}); };
  var peg$f35 = function(op, condition, addr) { return tInstruction(op, {addr, condition}); };
  var peg$f36 = function(op, dest, imm) { return tInstruction(op, {dest, imm}); };
  var peg$f37 = function() { expectedDataRegister() };
  var peg$f38 = function(dest, source) { return tInstruction(OPCODES.MOV, {dest, source}); };
  var peg$f39 = function(op, reg) { return tInstruction(op, {reg}); };
  var peg$f40 = function(op, imm) { return tInstruction(op, {imm}); };
  var peg$f41 = function(op, reg, imm) { return tInstruction(op, {reg, imm}); };
  var peg$f42 = function(op, imm, reg) { return tInstruction(op, {reg, imm}); };
  var peg$f43 = function(op, flag) { return tInstruction(op, {flag}); };
  var peg$f44 = function(op, addr) { return tInstruction(op, {bankReg: addr.bank, offsReg: addr.offs, immediate: addr.immediate}); };
  var peg$f45 = function() { return OPCODES.ADD; };
  var peg$f46 = function() { return OPCODES.AND; };
  var peg$f47 = function() { return OPCODES.BRK; };
  var peg$f48 = function() { return OPCODES.BR; };
  var peg$f49 = function() { return OPCODES.BRS; };
  var peg$f50 = function() { return OPCODES.CALL; };
  var peg$f51 = function() { return OPCODES.CALLS; };
  var peg$f52 = function() { return OPCODES.CLR; };
  var peg$f53 = function() { return OPCODES.CMP; };
  var peg$f54 = function() { return OPCODES.DEC; };
  var peg$f55 = function() { return OPCODES.DIV; };
  var peg$f56 = function() { return OPCODES.ENTER; };
  var peg$f57 = function() { return OPCODES.EXC; };
  var peg$f58 = function() { return OPCODES.EXIT; };
  var peg$f59 = function() { return OPCODES.HALT; };
  var peg$f60 = function() { return OPCODES.LD; };
  var peg$f61 = function() { return OPCODES.LOOP; };
  var peg$f62 = function() { return OPCODES.LOOPS; };
  var peg$f63 = function() { return OPCODES.IN; };
  var peg$f64 = function() { return OPCODES.INC; };
  var peg$f65 = function() { return OPCODES.MOD; };
  var peg$f66 = function() { return OPCODES.MOV; };
  var peg$f67 = function() { return OPCODES.MUL; };
  var peg$f68 = function() { return OPCODES.NEG; };
  var peg$f69 = function() { return OPCODES.NOP; };
  var peg$f70 = function() { return OPCODES.NOT; };
  var peg$f71 = function() { return OPCODES.OR; };
  var peg$f72 = function() { return OPCODES.OUT; };
  var peg$f73 = function() { return OPCODES.POP; };
  var peg$f74 = function() { return OPCODES.POPALL; };
  var peg$f75 = function() { return OPCODES.POPF; };
  var peg$f76 = function() { return OPCODES.PUSH; };
  var peg$f77 = function() { return OPCODES.PUSHALL; };
  var peg$f78 = function() { return OPCODES.PUSHF; };
  var peg$f79 = function() { return OPCODES.PUSHMM; };
  var peg$f80 = function() { return OPCODES.POPMM; };
  var peg$f81 = function() { return OPCODES.RET; };
  var peg$f82 = function() { return OPCODES.SDIV; };
  var peg$f83 = function() { return OPCODES.SET; };
  var peg$f84 = function() { return OPCODES.SHL; };
  var peg$f85 = function() { return OPCODES.SHR; };
  var peg$f86 = function() { return OPCODES.SMOD; };
  var peg$f87 = function() { return OPCODES.SMUL; };
  var peg$f88 = function() { return OPCODES.ST; };
  var peg$f89 = function() { return OPCODES.SUB; };
  var peg$f90 = function() { return OPCODES.SWAP; };
  var peg$f91 = function() { return OPCODES.TEST; };
  var peg$f92 = function() { return OPCODES.TRAP; };
  var peg$f93 = function() { return OPCODES.WAIT; };
  var peg$f94 = function() { return OPCODES.XOR; };
  var peg$f95 = function() { return OPCODES.FCLR; };
  var peg$f96 = function() { return OPCODES.FLDR; };
  var peg$f97 = function() { return OPCODES.FLDM; };
  var peg$f98 = function() { return OPCODES.FLDIM; };
  var peg$f99 = function() { return OPCODES.FSTR; };
  var peg$f100 = function() { return OPCODES.FSTM; };
  var peg$f101 = function() { return OPCODES.FSTIM; };
  var peg$f102 = function() { return OPCODES.FADD; };
  var peg$f103 = function() { return OPCODES.FSUB; };
  var peg$f104 = function() { return OPCODES.FCMP; };
  var peg$f105 = function() { return OPCODES.FMUL; };
  var peg$f106 = function() { return OPCODES.FDIV; };
  var peg$f107 = function() { return OPCODES.FMOD; };
  var peg$f108 = function() { return OPCODES.FPOW; };
  var peg$f109 = function() { return OPCODES.FSQRT; };
  var peg$f110 = function() { return OPCODES.FABS; };
  var peg$f111 = function() { return OPCODES.FSIN; };
  var peg$f112 = function() { return OPCODES.FCOS; };
  var peg$f113 = function() { return OPCODES.FTAN; };
  var peg$f114 = function() { return OPCODES.FASIN; };
  var peg$f115 = function() { return OPCODES.FACOS; };
  var peg$f116 = function() { return OPCODES.FATAN; };
  var peg$f117 = function() { return OPCODES.FNEG; };
  var peg$f118 = function() { return OPCODES.FEXC; };
  var peg$f119 = function() { return OPCODES.FINT; };
  var peg$f120 = function() { return OPCODES.FISNAN; };
  var peg$f121 = function() { return OPCODES.FISINF; };
  var peg$f122 = function() { return OPCODES.FLOG2; };
  var peg$f123 = function() { return OPCODES.FLOG10; };
  var peg$f124 = function() { return OPCODES.FLD0; };
  var peg$f125 = function() { return OPCODES.FLD1; };
  var peg$f126 = function() { return OPCODES.FLDE; };
  var peg$f127 = function() { return OPCODES.FLDPI; };
  var peg$f128 = function() { return "IF"; };
  var peg$f129 = function() { return "ELSE"; };
  var peg$f130 = function() { return "DO"; };
  var peg$f131 = function() { return "WHILE"; };
  var peg$f132 = function() { return "BREAK"; };
  var peg$f133 = function() { return "CONTINUE"; };
  var peg$f134 = function() { return "ADDRBANK"; };
  var peg$f135 = function() { return "ADDRBOFS"; };
  var peg$f136 = function() { return "ADDRPAGE"; };
  var peg$f137 = function() { return "ADDRPOFS"; };
  var peg$f138 = function() { return "ASC"; };
  var peg$f139 = function() { return "NEXT"; };
  var peg$f140 = function() { return tRegister(REGISTERS.A) };
  var peg$f141 = function() { return tRegister(REGISTERS.AL) };
  var peg$f142 = function() { return tRegister(REGISTERS.B) };
  var peg$f143 = function() { return tRegister(REGISTERS.BL) };
  var peg$f144 = function() { return tRegister(REGISTERS.C) };
  var peg$f145 = function() { return tRegister(REGISTERS.CL) };
  var peg$f146 = function() { return tRegister(REGISTERS.D) };
  var peg$f147 = function() { return tRegister(REGISTERS.DL) };
  var peg$f148 = function() { return tRegister(REGISTERS.X) };
  var peg$f149 = function() { return tRegister(REGISTERS.XL) };
  var peg$f150 = function() { return tRegister(REGISTERS.Y) };
  var peg$f151 = function() { return tRegister(REGISTERS.YL) };
  var peg$f152 = function() { return tRegister(REGISTERS.SP) };
  var peg$f153 = function() { return tRegister(REGISTERS.BP) };
  var peg$f154 = function() { return tFlag(FLAGS.ZERO); };
  var peg$f155 = function() { return tFlag(FLAGS.CARRY); };
  var peg$f156 = function() { return tFlag(FLAGS.OVERFLOW); };
  var peg$f157 = function() { return tFlag(FLAGS.NEGATIVE); };
  var peg$f158 = function() { return tFlag(FLAGS.EXCEPTION); };
  var peg$f159 = function() { return tFlag(FLAGS.INTERRUPT_DISABLE); };
  var peg$f160 = function() { return tFlag(FLAGS.INTERRUPT_SERVICE); };
  var peg$f161 = function() { return tFlag(FLAGS.SINGLE_STEP); };
  var peg$f162 = function(flag) { flag.neg = true; return flag; };
  var peg$f163 = function() { return tConditional("s<="); };
  var peg$f164 = function() { return tConditional("s<"); };
  var peg$f165 = function() { return tConditional("s>="); };
  var peg$f166 = function() { return tConditional("s>"); };
  var peg$f167 = function() { return tConditional("u>="); };
  var peg$f168 = function() { return tConditional("u>"); };
  var peg$f169 = function() { return tConditional("u<="); };
  var peg$f170 = function() { return tConditional("u<"); };
  var peg$f171 = function() { return tConditional("=="); };
  var peg$f172 = function() { return tConditional("!="); };
  var peg$f173 = function(imm) { return addressingMode({m: MODES.IMMEDIATE, addr: imm}); };
  var peg$f174 = function(offset) { return offset; };
  var peg$f175 = function(addr, x, y) { return addressingMode({addr, x: !!x, y: !!y, m:MODES.ABSOLUTE}); };
  var peg$f176 = function(addr, x, y) { return addressingMode({addr, x: !!x, y: !!y, i: true, m:MODES.ABSOLUTE}); };
  var peg$f177 = function(reg, imm, x, y) {
      return addressingMode({
          m: reg.idx === REGISTERS.D ? MODES.D : MODES.BP,
          addr: imm || 0,
          x: !!x,
          y: !!y
      });
  };
  var peg$f178 = function(reg, imm, x, y) {
      return addressingMode({
          m: reg.idx === REGISTERS.D ? MODES.D : MODES.BP,
          addr: imm || 0,
          x: !!x,
          y: !!y,
          i: true
      });
  };
  var peg$f179 = function(bank, offs) { return {indirect: false, bank, offs}; };
  var peg$f180 = function(bank, offs) { return {indirect: true, bank, offs}; };
  var peg$f181 = function(head, tail) {
      return tBinaryExpression(head, tail);
  };
  var peg$f182 = function(op, v) {
      return tUnaryExpression(op, v);
  };
  var peg$f183 = function(head, tail) {
      return [ head, ...tail.map(([,,,expr]) => expr)];
  };
  var peg$f184 = function(fn, expr) { return tFunction(fn, expr); };
  var peg$f185 = function(fn, expr) { return expectedStringLiteral() };
  var peg$f186 = function(expr) { return expr; };
  var peg$f187 = function(name) { return tLabel(name); };
  var peg$f188 = function() { error(`Label can not be a reserved word: ${text()}`); };
  var peg$f189 = function() { return tIdentifier(text()); };
  var peg$f190 = function(head, tail) { return text(); };
  var peg$f191 = function(head, tail) { return tLiteral(toNumber(text())); };
  var peg$f192 = function(negative, head, tail) { return tLiteral(toNumber(text())); };
  var peg$f193 = function(text) { return tLiteral(text.map(([,ch]) => ch).join("")); };
  var peg$f194 = function() { expectedQuote() };
  var peg$f195 = function() { return ""; };
  var peg$f196 = function() { return "["; };
  var peg$f197 = function() { return "]"; };
  var peg$f198 = function() { return "("; };
  var peg$f199 = function() { return ")"; };
  var peg$f200 = function() { return "<"; };
  var peg$f201 = function() { return ">"; };
  var peg$f202 = function() { return '"'; };
  var peg$f203 = function() { return "+"; };
  var peg$f204 = function() { return ","; };
  var peg$f205 = function() { return " "; };
  var peg$f206 = function(data) { return tComment(data.join("").trim()); };

  var peg$currPos = 0;
  var peg$savedPos = 0;
  var peg$posDetailsCache = [{ line: 1, column: 1 }];
  var peg$expected = [];
  var peg$silentFails = 0;

  var peg$result;

  if ("startRule" in options) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text() {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function offset() {
    return peg$savedPos;
  }

  function range() {
    return [peg$savedPos, peg$currPos];
  }

  function location() {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location
    );
  }

  function error(message, location) {
    location = location !== undefined
      ? location
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location);
  }

  function peg$literalExpectation(text, ignoreCase) {
    return { type: "literal", text: text, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts, inverted, ignoreCase) {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation() {
    return { type: "any" };
  }

  function peg$endExpectation() {
    return { type: "end" };
  }

  function peg$otherExpectation(description) {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos) {
    var details = peg$posDetailsCache[pos];
    var p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  var peg$VALIDFILENAME = typeof options.filename === "string" && options.filename.length > 0;
  function peg$computeLocation(startPos, endPos) {
    var loc = {};

    if ( peg$VALIDFILENAME ) loc.filename = options.filename;

    var startPosDetails = peg$computePosDetails(startPos);
    loc.start = {
      offset: startPos,
      line: startPosDetails.line,
      column: startPosDetails.column
    };

    var endPosDetails = peg$computePosDetails(endPos);
    loc.end = {
      offset: endPos,
      line: endPosDetails.line,
      column: endPosDetails.column
    };

    return loc;
  }

  function peg$begin() {
    peg$expected.push({ pos: peg$currPos, variants: [] });
  }

  function peg$expect(expected) {
    var top = peg$expected[peg$expected.length - 1];

    if (peg$currPos < top.pos) { return; }

    if (peg$currPos > top.pos) {
      top.pos = peg$currPos;
      top.variants = [];
    }

    top.variants.push(expected);
  }

  function peg$end(invert) {
    var expected = peg$expected.pop();
    var top = peg$expected[peg$expected.length - 1];
    var variants = expected.variants;

    if (top.pos !== expected.pos) { return; }

    if (invert) {
      variants = variants.map(function(e) {
        return e.type === "not" ? e.expected : { type: "not", expected: e };
      });
    }

    Array.prototype.push.apply(top.variants, variants);
  }

  function peg$buildSimpleError(message, location) {
    return new peg$SyntaxError(message, null, null, location);
  }

  function peg$buildStructuredError(expected, found, location) {
    return new peg$SyntaxError(
      peg$SyntaxError.buildMessage(expected, found),
      expected,
      found,
      location
    );
  }

  function peg$buildError() {
    var expected = peg$expected[0];
    var failPos = expected.pos;

    return peg$buildStructuredError(
      expected.variants,
      failPos < input.length ? input.charAt(failPos) : null,
      failPos < input.length
        ? peg$computeLocation(failPos, failPos + 1)
        : peg$computeLocation(failPos, failPos)
    );
  }

  function peg$parseProgram() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    rule$expects(peg$e0);
    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    s2 = peg$parseTopLevel();
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      s2 = peg$parseTopLevel();
    }
    peg$savedPos = s0;
    s1 = peg$f0(s1);
    s0 = s1;
    peg$silentFails--;

    return s0;
  }

  function peg$parseTopLevel() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parsedSegment();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      peg$savedPos = s0;
      s0 = peg$f1(s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      s2 = peg$parsedImport();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        peg$savedPos = s0;
        s0 = peg$f1(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parse_();
        s2 = peg$parsedNamespace();
        if (s2 !== peg$FAILED) {
          s3 = peg$parse_();
          peg$savedPos = s0;
          s0 = peg$f1(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parse_();
          s2 = peg$parsedMacro();
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            peg$savedPos = s0;
            s0 = peg$f1(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parse_();
            s2 = peg$parseMacroExpansion();
            if (s2 !== peg$FAILED) {
              s3 = peg$parse_();
              peg$savedPos = s0;
              s0 = peg$f1(s2);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parse_();
              s2 = peg$parseCOMMENT();
              if (s2 !== peg$FAILED) {
                s3 = peg$parse_();
                peg$savedPos = s0;
                s0 = peg$f1(s2);
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parse_();
                s2 = peg$parseEOL();
                if (s2 !== peg$FAILED) {
                  s3 = peg$parse_();
                  peg$savedPos = s0;
                  s0 = peg$f2();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseBlock() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseLCURLY();
    if (s2 !== peg$FAILED) {
      s3 = [];
      s4 = peg$parseLine();
      while (s4 !== peg$FAILED) {
        s3.push(s4);
        s4 = peg$parseLine();
      }
      s4 = peg$parseRCURLY();
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        peg$savedPos = s0;
        s0 = peg$f3(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLine() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseEOL();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      peg$savedPos = s0;
      s0 = peg$f2();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseBlock();
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parse_();
        s2 = peg$parseLabel();
        if (s2 === peg$FAILED) {
          s2 = null;
        }
        s3 = peg$parse_();
        s4 = peg$parsehlStatement();
        if (s4 === peg$FAILED) {
          s4 = peg$parseInstruction();
          if (s4 === peg$FAILED) {
            s4 = peg$parseDirective();
            if (s4 === peg$FAILED) {
              s4 = peg$parseBlock();
            }
          }
        }
        if (s4 === peg$FAILED) {
          s4 = null;
        }
        s5 = peg$parse_();
        s6 = peg$parseCOMMENT();
        if (s6 === peg$FAILED) {
          s6 = null;
        }
        s7 = peg$parse_();
        s8 = peg$parseEOL();
        if (s8 !== peg$FAILED) {
          s9 = peg$parse_();
          peg$savedPos = s0;
          s0 = peg$f4(s2, s4, s6);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parse_();
          s2 = peg$parseMacroExpansion();
          if (s2 !== peg$FAILED) {
            s3 = peg$parse_();
            peg$savedPos = s0;
            s0 = peg$f1(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parsehlStatement() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parsehlIF();
    if (s0 === peg$FAILED) {
      s0 = peg$parsehlDO();
      if (s0 === peg$FAILED) {
        s0 = peg$parsehlBreak();
        if (s0 === peg$FAILED) {
          s0 = peg$parsehlContinue();
        }
      }
    }

    return s0;
  }

  function peg$parsehlElse() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseELSE();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseBlock();
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        peg$savedPos = s0;
        s0 = peg$f5(s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehlBreak() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseBREAK();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      peg$savedPos = s0;
      s0 = peg$f6();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehlContinue() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseCONTINUE();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      peg$savedPos = s0;
      s0 = peg$f7();
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehlIF() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseIF();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseAllFlags();
      if (s4 === peg$FAILED) {
        s4 = peg$parseAllConditionals();
      }
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        s6 = peg$parseBlock();
        if (s6 !== peg$FAILED) {
          s7 = peg$parse_();
          s8 = peg$parsehlElse();
          if (s8 === peg$FAILED) {
            s8 = null;
          }
          peg$savedPos = s0;
          s0 = peg$f8(s4, s6, s8);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsehlDO() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseDO();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseBlock();
      if (s4 !== peg$FAILED) {
        s5 = peg$parse_();
        s6 = peg$parseWHILE();
        if (s6 !== peg$FAILED) {
          s7 = peg$parse_();
          s8 = peg$parseAllFlags();
          if (s8 === peg$FAILED) {
            s8 = peg$parseAllConditionals();
          }
          if (s8 !== peg$FAILED) {
            s9 = peg$parse_();
            peg$savedPos = s0;
            s0 = peg$f9(s4, s8);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parse_();
      s2 = peg$parseWHILE();
      if (s2 !== peg$FAILED) {
        s3 = peg$parse_();
        s4 = peg$parseAllFlags();
        if (s4 === peg$FAILED) {
          s4 = peg$parseAllConditionals();
        }
        if (s4 !== peg$FAILED) {
          s5 = peg$parse_();
          s6 = peg$parseDO();
          if (s6 !== peg$FAILED) {
            s7 = peg$parse_();
            s8 = peg$parseBlock();
            if (s8 !== peg$FAILED) {
              s9 = peg$parse_();
              peg$savedPos = s0;
              s0 = peg$f10(s4, s8);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseAST() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseRegister();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAllFlags();
      if (s0 === peg$FAILED) {
        s0 = peg$parseAllConditionals();
        if (s0 === peg$FAILED) {
          s0 = peg$parseStringOrConstantExpression();
          if (s0 === peg$FAILED) {
            s0 = peg$parseMemoryAddressingMode();
            if (s0 === peg$FAILED) {
              s0 = peg$parseBranchAddressingMode();
              if (s0 === peg$FAILED) {
                s0 = peg$parseLine();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseProgram();
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseASTList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseAST();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parse_();
    s3 = [];
    s4 = peg$currPos;
    s5 = peg$parseCOMMA();
    if (s5 !== peg$FAILED) {
      s6 = peg$parse_();
      s7 = peg$parseAST();
      if (s7 !== peg$FAILED) {
        s8 = peg$parse_();
        s5 = [s5, s6, s7, s8];
        s4 = s5;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
    } else {
      peg$currPos = s4;
      s4 = peg$FAILED;
    }
    while (s4 !== peg$FAILED) {
      s3.push(s4);
      s4 = peg$currPos;
      s5 = peg$parseCOMMA();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseAST();
        if (s7 !== peg$FAILED) {
          s8 = peg$parse_();
          s5 = [s5, s6, s7, s8];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s0 = peg$f11(s1, s3);

    return s0;
  }

  function peg$parseIdentifierList() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIdentifier();
    if (s1 === peg$FAILED) {
      s1 = null;
    }
    s2 = peg$parse_();
    s3 = [];
    s4 = peg$currPos;
    s5 = peg$parseCOMMA();
    if (s5 !== peg$FAILED) {
      s6 = peg$parse_();
      s7 = peg$parseIdentifier();
      if (s7 !== peg$FAILED) {
        s8 = peg$parse_();
        s5 = [s5, s6, s7, s8];
        s4 = s5;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
    } else {
      peg$currPos = s4;
      s4 = peg$FAILED;
    }
    while (s4 !== peg$FAILED) {
      s3.push(s4);
      s4 = peg$currPos;
      s5 = peg$parseCOMMA();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseIdentifier();
        if (s7 !== peg$FAILED) {
          s8 = peg$parse_();
          s5 = [s5, s6, s7, s8];
          s4 = s5;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s0 = peg$f11(s1, s3);

    return s0;
  }

  function peg$parseMacroExpansion() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIdentifier();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseLPAREN();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseASTList();
        s6 = peg$parse_();
        s7 = peg$parseRPAREN();
        if (s7 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f12(s1, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDirective() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parsedSegment();
    if (s0 === peg$FAILED) {
      s0 = peg$parsedByte();
      if (s0 === peg$FAILED) {
        s0 = peg$parsedWord();
        if (s0 === peg$FAILED) {
          s0 = peg$parsedString();
          if (s0 === peg$FAILED) {
            s0 = peg$parsedConstant();
            if (s0 === peg$FAILED) {
              s0 = peg$parsedImport();
              if (s0 === peg$FAILED) {
                s0 = peg$parsedNamespace();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsedMacro();
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedSegment() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSEGMENT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIdentifier();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseOrExpression();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseAPPEND();
          if (s7 === peg$FAILED) {
            s7 = null;
          }
          s8 = peg$parse_();
          s9 = peg$parseBlock();
          if (s9 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f13(s3, s5, s7, s9);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedNamespace() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseNAMESPACE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIdentifier();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseBlock();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f14(s3, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedConstant() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCONST();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIdentifier();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseOrExpression();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f15(s3, s5);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedImport() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIMPORT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseStringLiteral();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f16(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedByte() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseBYTE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseARRAY();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCommaSepExpressions();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        peg$savedPos = s0;
        s0 = peg$f17(s3, s5);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseBYTE();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseCommaSepExpressions();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f18(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsedWord() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseWORD();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseARRAY();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCommaSepExpressions();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        peg$savedPos = s0;
        s0 = peg$f19(s3, s5);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseWORD();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseCommaSepExpressions();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f20(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsedString() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSTRING();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseCommaSepStringOrConstantExpressions();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f21(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedMacro() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseMACRO();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseIdentifier();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseLPAREN();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseIdentifierList();
          s8 = peg$parse_();
          s9 = peg$parseRPAREN();
          if (s9 !== peg$FAILED) {
            s10 = peg$parse_();
            s11 = peg$parseAST();
            if (s11 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f22(s3, s7, s11);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSEGMENT() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c0) {
      s0 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBYTE() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c1) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseWORD() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c2) {
      s0 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSTRING() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c3) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAPPEND() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c4) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCONST() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c5) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIMPORT() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c6) {
      s0 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNAMESPACE() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 10).toLowerCase() === peg$c7) {
      s0 = input.substr(peg$currPos, 10);
      peg$currPos += 10;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseARRAY() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLBRACKET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseOrExpression();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseRBRACKET();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f23(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMACRO() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c8) {
      s0 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c9) {
        s0 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseDirectiveKeywords() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseSEGMENT();
    if (s0 === peg$FAILED) {
      s0 = peg$parseBYTE();
      if (s0 === peg$FAILED) {
        s0 = peg$parseWORD();
        if (s0 === peg$FAILED) {
          s0 = peg$parseSTRING();
          if (s0 === peg$FAILED) {
            s0 = peg$parseAPPEND();
            if (s0 === peg$FAILED) {
              s0 = peg$parseCONST();
              if (s0 === peg$FAILED) {
                s0 = peg$parseIMPORT();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseNAMESPACE();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseARRAY();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseMACRO();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseExpectedAssembly() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c10;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseInteger();
      if (s5 !== peg$FAILED) {
        s4 = [s4, s5];
        s3 = s4;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseInteger();
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      if (input.charCodeAt(peg$currPos) === 125) {
        s3 = peg$c11;
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f24(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseInstruction() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseiNOP();
    if (s1 === peg$FAILED) {
      s1 = peg$parseiADD();
      if (s1 === peg$FAILED) {
        s1 = peg$parseiAND();
        if (s1 === peg$FAILED) {
          s1 = peg$parseiCMP();
          if (s1 === peg$FAILED) {
            s1 = peg$parseiSUB();
            if (s1 === peg$FAILED) {
              s1 = peg$parseiOR();
              if (s1 === peg$FAILED) {
                s1 = peg$parseiTEST();
                if (s1 === peg$FAILED) {
                  s1 = peg$parseiXOR();
                  if (s1 === peg$FAILED) {
                    s1 = peg$parseiTRAP();
                    if (s1 === peg$FAILED) {
                      s1 = peg$parseiNOT();
                      if (s1 === peg$FAILED) {
                        s1 = peg$parseiNEG();
                        if (s1 === peg$FAILED) {
                          s1 = peg$parseiEXC();
                          if (s1 === peg$FAILED) {
                            s1 = peg$parseiSHL();
                            if (s1 === peg$FAILED) {
                              s1 = peg$parseiSHR();
                              if (s1 === peg$FAILED) {
                                s1 = peg$parseiSWAP();
                                if (s1 === peg$FAILED) {
                                  s1 = peg$parseiMOV();
                                  if (s1 === peg$FAILED) {
                                    s1 = peg$parseiLOAD();
                                    if (s1 === peg$FAILED) {
                                      s1 = peg$parseiSTORE();
                                      if (s1 === peg$FAILED) {
                                        s1 = peg$parseiINC();
                                        if (s1 === peg$FAILED) {
                                          s1 = peg$parseiIN();
                                          if (s1 === peg$FAILED) {
                                            s1 = peg$parseiOUT();
                                            if (s1 === peg$FAILED) {
                                              s1 = peg$parseiENTER();
                                              if (s1 === peg$FAILED) {
                                                s1 = peg$parseiEXIT();
                                                if (s1 === peg$FAILED) {
                                                  s1 = peg$parseiBRK();
                                                  if (s1 === peg$FAILED) {
                                                    s1 = peg$parseiLOOP();
                                                    if (s1 === peg$FAILED) {
                                                      s1 = peg$parseiBR();
                                                      if (s1 === peg$FAILED) {
                                                        s1 = peg$parseiCALL();
                                                        if (s1 === peg$FAILED) {
                                                          s1 = peg$parseiPUSHALL();
                                                          if (s1 === peg$FAILED) {
                                                            s1 = peg$parseiPOPALL();
                                                            if (s1 === peg$FAILED) {
                                                              s1 = peg$parseiPUSHF();
                                                              if (s1 === peg$FAILED) {
                                                                s1 = peg$parseiPOPMM();
                                                                if (s1 === peg$FAILED) {
                                                                  s1 = peg$parseiPUSHMM();
                                                                  if (s1 === peg$FAILED) {
                                                                    s1 = peg$parseiPOPF();
                                                                    if (s1 === peg$FAILED) {
                                                                      s1 = peg$parseiPUSH();
                                                                      if (s1 === peg$FAILED) {
                                                                        s1 = peg$parseiPOP();
                                                                        if (s1 === peg$FAILED) {
                                                                          s1 = peg$parseiRET();
                                                                          if (s1 === peg$FAILED) {
                                                                            s1 = peg$parseiMUL();
                                                                            if (s1 === peg$FAILED) {
                                                                              s1 = peg$parseiMOD();
                                                                              if (s1 === peg$FAILED) {
                                                                                s1 = peg$parseiDIV();
                                                                                if (s1 === peg$FAILED) {
                                                                                  s1 = peg$parseiSMUL();
                                                                                  if (s1 === peg$FAILED) {
                                                                                    s1 = peg$parseiSMOD();
                                                                                    if (s1 === peg$FAILED) {
                                                                                      s1 = peg$parseiSDIV();
                                                                                      if (s1 === peg$FAILED) {
                                                                                        s1 = peg$parseiSET();
                                                                                        if (s1 === peg$FAILED) {
                                                                                          s1 = peg$parseiCLR();
                                                                                          if (s1 === peg$FAILED) {
                                                                                            s1 = peg$parseiDEC();
                                                                                            if (s1 === peg$FAILED) {
                                                                                              s1 = peg$parseiHALT();
                                                                                              if (s1 === peg$FAILED) {
                                                                                                s1 = peg$parseiWAIT();
                                                                                                if (s1 === peg$FAILED) {
                                                                                                  s1 = peg$parseiFCLR();
                                                                                                  if (s1 === peg$FAILED) {
                                                                                                    s1 = peg$parseiFADD();
                                                                                                    if (s1 === peg$FAILED) {
                                                                                                      s1 = peg$parseiFSUB();
                                                                                                      if (s1 === peg$FAILED) {
                                                                                                        s1 = peg$parseiFCMP();
                                                                                                        if (s1 === peg$FAILED) {
                                                                                                          s1 = peg$parseiFMUL();
                                                                                                          if (s1 === peg$FAILED) {
                                                                                                            s1 = peg$parseiFMOD();
                                                                                                            if (s1 === peg$FAILED) {
                                                                                                              s1 = peg$parseiFDIV();
                                                                                                              if (s1 === peg$FAILED) {
                                                                                                                s1 = peg$parseiFPOW();
                                                                                                                if (s1 === peg$FAILED) {
                                                                                                                  s1 = peg$parseiFSQRT();
                                                                                                                  if (s1 === peg$FAILED) {
                                                                                                                    s1 = peg$parseiFNEG();
                                                                                                                    if (s1 === peg$FAILED) {
                                                                                                                      s1 = peg$parseiFEXC();
                                                                                                                      if (s1 === peg$FAILED) {
                                                                                                                        s1 = peg$parseiFINT();
                                                                                                                        if (s1 === peg$FAILED) {
                                                                                                                          s1 = peg$parseiFABS();
                                                                                                                          if (s1 === peg$FAILED) {
                                                                                                                            s1 = peg$parseiFSIN();
                                                                                                                            if (s1 === peg$FAILED) {
                                                                                                                              s1 = peg$parseiFCOS();
                                                                                                                              if (s1 === peg$FAILED) {
                                                                                                                                s1 = peg$parseiFTAN();
                                                                                                                                if (s1 === peg$FAILED) {
                                                                                                                                  s1 = peg$parseiFASIN();
                                                                                                                                  if (s1 === peg$FAILED) {
                                                                                                                                    s1 = peg$parseiFACOS();
                                                                                                                                    if (s1 === peg$FAILED) {
                                                                                                                                      s1 = peg$parseiFATAN();
                                                                                                                                      if (s1 === peg$FAILED) {
                                                                                                                                        s1 = peg$parseiFISNAN();
                                                                                                                                        if (s1 === peg$FAILED) {
                                                                                                                                          s1 = peg$parseiFISINF();
                                                                                                                                          if (s1 === peg$FAILED) {
                                                                                                                                            s1 = peg$parseiFLOG2();
                                                                                                                                            if (s1 === peg$FAILED) {
                                                                                                                                              s1 = peg$parseiFLOG10();
                                                                                                                                              if (s1 === peg$FAILED) {
                                                                                                                                                s1 = peg$parseiFLD0();
                                                                                                                                                if (s1 === peg$FAILED) {
                                                                                                                                                  s1 = peg$parseiFLD1();
                                                                                                                                                  if (s1 === peg$FAILED) {
                                                                                                                                                    s1 = peg$parseiFLDE();
                                                                                                                                                    if (s1 === peg$FAILED) {
                                                                                                                                                      s1 = peg$parseiFLDPI();
                                                                                                                                                      if (s1 === peg$FAILED) {
                                                                                                                                                        s1 = peg$parseiFLDR();
                                                                                                                                                        if (s1 === peg$FAILED) {
                                                                                                                                                          s1 = peg$parseiFLDM();
                                                                                                                                                          if (s1 === peg$FAILED) {
                                                                                                                                                            s1 = peg$parseiFLDIM();
                                                                                                                                                            if (s1 === peg$FAILED) {
                                                                                                                                                              s1 = peg$parseiFSTR();
                                                                                                                                                              if (s1 === peg$FAILED) {
                                                                                                                                                                s1 = peg$parseiFSTM();
                                                                                                                                                                if (s1 === peg$FAILED) {
                                                                                                                                                                  s1 = peg$parseiFSTIM();
                                                                                                                                                                }
                                                                                                                                                              }
                                                                                                                                                            }
                                                                                                                                                          }
                                                                                                                                                        }
                                                                                                                                                      }
                                                                                                                                                    }
                                                                                                                                                  }
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          }
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            }
                                                                                                                          }
                                                                                                                        }
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseExpectedAssembly();
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      peg$savedPos = s0;
      s0 = peg$f25(s1, s3);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiNOP() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseNOP();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiBRK() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseBRK();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiHALT() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseHALT();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPUSHALL() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePUSHALL();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPOPALL() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePOPALL();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPUSHF() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePUSHF();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPOPF() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePOPF();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPUSHMM() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePUSHMM();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiPOPMM() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePOPMM();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiRET() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseRET();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiLOAD() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLD();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$currPos;
      peg$begin();
      s4 = peg$parseRegister();
      peg$end(true);
      if (s4 === peg$FAILED) {
        s3 = undefined;
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f27();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseLD();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$currPos;
          peg$begin();
          s6 = peg$parseCOMMA();
          peg$end(true);
          if (s6 === peg$FAILED) {
            s5 = undefined;
          } else {
            peg$currPos = s5;
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f28();
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseLD();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$currPos;
              peg$begin();
              s8 = peg$parseMemoryAddressingMode();
              peg$end(true);
              if (s8 === peg$FAILED) {
                s7 = undefined;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f29();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseLD();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$parseCOMMA();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                s7 = peg$parseMemoryAddressingMode();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f30(s1, s3, s7);
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseRegister();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$parseOP_TAKES();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                s5 = peg$parseMemoryAddressingMode();
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f31(s1, s5);
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiSTORE() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseST();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseMemoryAddressingMode();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseMemoryAddressingMode();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseOP_TAKES();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseRegister();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f32(s1, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseST();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseMemoryAddressingMode();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$currPos;
              peg$begin();
              s8 = peg$parseRegister();
              peg$end(true);
              if (s8 === peg$FAILED) {
                s7 = undefined;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseST();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseMemoryAddressingMode();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseST();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseMemoryAddressingMode();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f29();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiLOOP() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLOOPS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseBranchAddressingMode();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f33(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseLOOP();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseBranchAddressingMode();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseRegister();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f33(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseLOOPS();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseBranchAddressingMode();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$currPos;
              peg$begin();
              s8 = peg$parseRegister();
              peg$end(true);
              if (s8 === peg$FAILED) {
                s7 = undefined;
              } else {
                peg$currPos = s7;
                s7 = peg$FAILED;
              }
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseLOOP();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseBranchAddressingMode();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$parseCOMMA();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                s7 = peg$currPos;
                peg$begin();
                s8 = peg$parseRegister();
                peg$end(true);
                if (s8 === peg$FAILED) {
                  s7 = undefined;
                } else {
                  peg$currPos = s7;
                  s7 = peg$FAILED;
                }
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f27();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseLOOPS();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$parseBranchAddressingMode();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                s5 = peg$currPos;
                peg$begin();
                s6 = peg$parseCOMMA();
                peg$end(true);
                if (s6 === peg$FAILED) {
                  s5 = undefined;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f28();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseLOOP();
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseBranchAddressingMode();
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  s5 = peg$currPos;
                  peg$begin();
                  s6 = peg$parseCOMMA();
                  peg$end(true);
                  if (s6 === peg$FAILED) {
                    s5 = undefined;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f28();
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseLOOPS();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  s3 = peg$currPos;
                  peg$begin();
                  s4 = peg$parseBranchAddressingMode();
                  peg$end(true);
                  if (s4 === peg$FAILED) {
                    s3 = undefined;
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                  if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f29();
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parseLOOP();
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    s3 = peg$currPos;
                    peg$begin();
                    s4 = peg$parseBranchAddressingMode();
                    peg$end(true);
                    if (s4 === peg$FAILED) {
                      s3 = undefined;
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                    if (s3 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f29();
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiCALL() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCALLS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseAllFlags();
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = peg$parse_();
      s5 = peg$parseCOMMA();
      if (s5 === peg$FAILED) {
        s5 = null;
      }
      s6 = peg$parse_();
      s7 = peg$parseBranchAddressingMode();
      if (s7 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f34(s1, s3, s7);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseCALLS();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseAllConditionals();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseBranchAddressingMode();
        if (s7 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f35(s1, s3, s7);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseCALL();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseAllFlags();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          s6 = peg$parse_();
          s7 = peg$parseBranchAddressingMode();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f34(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseCALL();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseAllConditionals();
            if (s3 === peg$FAILED) {
              s3 = null;
            }
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            s6 = peg$parse_();
            s7 = peg$parseBranchAddressingMode();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f35(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseCALLS();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$parseAllFlags();
              if (s3 === peg$FAILED) {
                s3 = null;
              }
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseBranchAddressingMode();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f29();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseCALLS();
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseAllConditionals();
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                s4 = peg$parse_();
                s5 = peg$currPos;
                peg$begin();
                s6 = peg$parseBranchAddressingMode();
                peg$end(true);
                if (s6 === peg$FAILED) {
                  s5 = undefined;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f29();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseCALL();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  s3 = peg$parseAllFlags();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  s4 = peg$parse_();
                  s5 = peg$currPos;
                  peg$begin();
                  s6 = peg$parseBranchAddressingMode();
                  peg$end(true);
                  if (s6 === peg$FAILED) {
                    s5 = undefined;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f29();
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parseCALL();
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    s3 = peg$parseAllConditionals();
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    s4 = peg$parse_();
                    s5 = peg$currPos;
                    peg$begin();
                    s6 = peg$parseBranchAddressingMode();
                    peg$end(true);
                    if (s6 === peg$FAILED) {
                      s5 = undefined;
                    } else {
                      peg$currPos = s5;
                      s5 = peg$FAILED;
                    }
                    if (s5 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f29();
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiBR() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseBRS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseAllFlags();
      if (s3 === peg$FAILED) {
        s3 = null;
      }
      s4 = peg$parse_();
      s5 = peg$parseCOMMA();
      if (s5 === peg$FAILED) {
        s5 = null;
      }
      s6 = peg$parse_();
      s7 = peg$parseBranchAddressingMode();
      if (s7 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f34(s1, s3, s7);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseBRS();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseAllConditionals();
        if (s3 === peg$FAILED) {
          s3 = null;
        }
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseBranchAddressingMode();
        if (s7 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f35(s1, s3, s7);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseBR();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseAllFlags();
          if (s3 === peg$FAILED) {
            s3 = null;
          }
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 === peg$FAILED) {
            s5 = null;
          }
          s6 = peg$parse_();
          s7 = peg$parseBranchAddressingMode();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f34(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseBR();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseAllConditionals();
            if (s3 === peg$FAILED) {
              s3 = null;
            }
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 === peg$FAILED) {
              s5 = null;
            }
            s6 = peg$parse_();
            s7 = peg$parseBranchAddressingMode();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f35(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseBRS();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$parseAllFlags();
              if (s3 === peg$FAILED) {
                s3 = null;
              }
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseBranchAddressingMode();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f29();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseBRS();
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseAllConditionals();
                if (s3 === peg$FAILED) {
                  s3 = null;
                }
                s4 = peg$parse_();
                s5 = peg$currPos;
                peg$begin();
                s6 = peg$parseBranchAddressingMode();
                peg$end(true);
                if (s6 === peg$FAILED) {
                  s5 = undefined;
                } else {
                  peg$currPos = s5;
                  s5 = peg$FAILED;
                }
                if (s5 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f29();
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseBR();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  s3 = peg$parseAllFlags();
                  if (s3 === peg$FAILED) {
                    s3 = null;
                  }
                  s4 = peg$parse_();
                  s5 = peg$currPos;
                  peg$begin();
                  s6 = peg$parseBranchAddressingMode();
                  peg$end(true);
                  if (s6 === peg$FAILED) {
                    s5 = undefined;
                  } else {
                    peg$currPos = s5;
                    s5 = peg$FAILED;
                  }
                  if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f29();
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$parseBR();
                  if (s1 !== peg$FAILED) {
                    s2 = peg$parse_();
                    s3 = peg$parseAllConditionals();
                    if (s3 === peg$FAILED) {
                      s3 = null;
                    }
                    s4 = peg$parse_();
                    s5 = peg$currPos;
                    peg$begin();
                    s6 = peg$parseBranchAddressingMode();
                    peg$end(true);
                    if (s6 === peg$FAILED) {
                      s5 = undefined;
                    } else {
                      peg$currPos = s5;
                      s5 = peg$FAILED;
                    }
                    if (s5 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f29();
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiADD() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseADD();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseADD();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseADD();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseADD();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseADD();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiAND() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseAND();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseAND();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseAND();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseAND();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseAND();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiCMP() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCMP();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseCMP();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseCMP();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseCMP();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseCMP();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiSUB() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSUB();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSUB();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseSUB();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseSUB();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseSUB();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiOR() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseOR();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseOR();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseOR();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseOR();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiXOR() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseXOR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseXOR();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseXOR();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseXOR();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseXOR();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiTEST() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseTEST();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseTEST();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseGeneralRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseTEST();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseNonGeneralRegister();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseCOMMA();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseOrExpression();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f37();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseTEST();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseRegister();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$currPos;
              peg$begin();
              s6 = peg$parseCOMMA();
              peg$end(true);
              if (s6 === peg$FAILED) {
                s5 = undefined;
              } else {
                peg$currPos = s5;
                s5 = peg$FAILED;
              }
              if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f28();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseTEST();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$currPos;
              peg$begin();
              s4 = peg$parseRegister();
              peg$end(true);
              if (s4 === peg$FAILED) {
                s3 = undefined;
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f27();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseiSHL() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSHL();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSHL();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseImmediate4();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiSHR() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSHR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSHR();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseImmediate4();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f36(s1, s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiDIV() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseDIV();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseDIV();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiMUL() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseMUL();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseMUL();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiMOD() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseMOD();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseMOD();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiSDIV() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSDIV();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSDIV();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiSMUL() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSMUL();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSMUL();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiSMOD() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSMOD();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseSMOD();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseRegister();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseCOMMA();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseOrExpression();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f27();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiSWAP() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSWAP();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiMOV() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseMOV();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f30(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseRegister();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseOP_TAKES();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseRegister();
          if (s5 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f38(s1, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiNOT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseNOT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiNEG() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseNEG();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiEXC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseEXC();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiTRAP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseTRAP();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseTRAP();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseImmediate8();
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f40(s1, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseiIN() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIN();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseImmediate8();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f41(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiOUT() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOUT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseImmediate8();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f42(s1, s3, s7);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiENTER() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseENTER();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseImmediate8();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f40(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiEXIT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseEXIT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseImmediate8();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f40(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiWAIT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseWAIT();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseImmediate8();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f40(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiSET() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseSET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFlags();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f43(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiCLR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCLR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFlags();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f43(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiINC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseINC();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiDEC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseDEC();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiPUSH() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePUSH();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiPOP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePOP();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFCLR() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFCLR();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLDR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLDR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFLDM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLDM();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFPAbsolute();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f44(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFLDIM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLDIM();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFPIndirect();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f44(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFSTR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSTR();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f39(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFSTM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSTM();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFPAbsolute();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f44(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFSTIM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSTIM();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseFPIndirect();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f44(s1, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseiFADD() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFADD();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFSUB() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSUB();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFCMP() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFCMP();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFMUL() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFMUL();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFMOD() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFMOD();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFDIV() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFDIV();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFABS() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFABS();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFPOW() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFPOW();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFSQRT() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSQRT();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFSIN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFSIN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFCOS() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFCOS();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFTAN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFTAN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFASIN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFASIN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFACOS() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFACOS();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFATAN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFATAN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFNEG() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFNEG();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFEXC() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFEXC();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFINT() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFINT();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFISNAN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFISNAN();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFISINF() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFISINF();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLOG2() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLOG2();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLOG10() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLOG10();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLD0() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLD0();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLD1() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLD1();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLDE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLDE();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseiFLDPI() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseFLDPI();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f26(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseADD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c12) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f45();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAND() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c13) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f46();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBRK() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c14) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f47();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c15) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f48();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBRS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c16) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f49();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCALL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c17) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f50();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCALLS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c18) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f51();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCLR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c19) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f52();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCMP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c20) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f53();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDEC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c21) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f54();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDIV() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c22) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f55();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseENTER() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c23) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f56();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEXC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c24) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f57();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseEXIT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c25) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f58();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseHALT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c26) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f59();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c27) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f60();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLOOP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c28) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f61();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseLOOPS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c29) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f62();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c30) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f63();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseINC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c31) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f64();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMOD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c32) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f65();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMOV() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c33) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f66();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMUL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c34) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f67();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNEG() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c35) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f68();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNOP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c36) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f69();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNOT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c37) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f70();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c38) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f71();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOUT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c39) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f72();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePOP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c40) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f73();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePOPALL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c41) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f74();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePOPF() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c42) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f75();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePUSH() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c43) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f76();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePUSHALL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7).toLowerCase() === peg$c44) {
      s1 = input.substr(peg$currPos, 7);
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f77();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePUSHF() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c45) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f78();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePUSHMM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c46) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f79();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsePOPMM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c47) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f80();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRET() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c48) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f81();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSDIV() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c49) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f82();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSET() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c50) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f83();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSHL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c51) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f84();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSHR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c52) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f85();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSMOD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c53) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f86();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSMUL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c54) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f87();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseST() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c55) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f88();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSUB() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c56) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f89();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseSWAP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c57) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f90();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTEST() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c58) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f91();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseTRAP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c59) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f92();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseWAIT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c60) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f93();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseXOR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c61) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f94();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFCLR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c62) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f95();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLDR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c63) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f96();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLDM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c64) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f97();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLDIM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c65) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f98();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSTR() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c66) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f99();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSTM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c67) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f100();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSTIM() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c68) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f101();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFADD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c69) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f102();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSUB() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c70) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f103();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFCMP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c71) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f104();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFMUL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c72) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f105();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFDIV() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c73) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f106();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFMOD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c74) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f107();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFPOW() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c75) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f108();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSQRT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c76) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f109();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFABS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c77) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f110();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFSIN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c78) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f111();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFCOS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c79) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f112();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFTAN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c80) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f113();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFASIN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c81) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f114();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFACOS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c82) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f115();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFATAN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c83) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f116();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFNEG() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c84) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f117();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFEXC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c85) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f118();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFINT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c86) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f119();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFISNAN() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c87) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f120();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFISINF() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c88) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f121();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLOG2() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c89) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f122();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLOG10() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6).toLowerCase() === peg$c90) {
      s1 = input.substr(peg$currPos, 6);
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f123();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLD0() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c91) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f124();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLD1() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c92) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f125();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLDE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c93) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f126();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFLDPI() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c94) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f127();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIF() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c95) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f128();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseELSE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c96) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f129();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseDO() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c97) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f130();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseWHILE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c98) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f131();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseBREAK() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c99) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f132();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCONTINUE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c100) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f133();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseADDRBANK() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c101) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f134();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c102) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$begin();
        s3 = peg$parseIdentifierPart();
        peg$end(true);
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f134();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseADDRBOFS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c103) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f135();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c104) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$begin();
        s3 = peg$parseIdentifierPart();
        peg$end(true);
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f135();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseADDRPAGE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c105) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f136();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c106) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$begin();
        s3 = peg$parseIdentifierPart();
        peg$end(true);
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f136();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseADDRPOFS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8).toLowerCase() === peg$c107) {
      s1 = input.substr(peg$currPos, 8);
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f137();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c108) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$begin();
        s3 = peg$parseIdentifierPart();
        peg$end(true);
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f137();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseASC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c109) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f138();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNEXT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c110) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f139();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseKeyword() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseADD();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAND();
      if (s0 === peg$FAILED) {
        s0 = peg$parseBRK();
        if (s0 === peg$FAILED) {
          s0 = peg$parseBRS();
          if (s0 === peg$FAILED) {
            s0 = peg$parseBR();
            if (s0 === peg$FAILED) {
              s0 = peg$parseCALLS();
              if (s0 === peg$FAILED) {
                s0 = peg$parseCALL();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseCLR();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseCMP();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseDEC();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseDIV();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseENTER();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseEXC();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseEXIT();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseLD();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseLOOPS();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseLOOP();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseINC();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseIN();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseMOD();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseMOV();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parseMUL();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parseNEG();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parseNOP();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parseNOT();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseOR();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseOUT();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parsePOPALL();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parsePOPF();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parsePOP();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parsePUSHALL();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parsePUSHF();
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$parsePOPMM();
                                                                    if (s0 === peg$FAILED) {
                                                                      s0 = peg$parsePUSHMM();
                                                                      if (s0 === peg$FAILED) {
                                                                        s0 = peg$parsePUSH();
                                                                        if (s0 === peg$FAILED) {
                                                                          s0 = peg$parseRET();
                                                                          if (s0 === peg$FAILED) {
                                                                            s0 = peg$parseSDIV();
                                                                            if (s0 === peg$FAILED) {
                                                                              s0 = peg$parseSET();
                                                                              if (s0 === peg$FAILED) {
                                                                                s0 = peg$parseSMOD();
                                                                                if (s0 === peg$FAILED) {
                                                                                  s0 = peg$parseSMUL();
                                                                                  if (s0 === peg$FAILED) {
                                                                                    s0 = peg$parseSHL();
                                                                                    if (s0 === peg$FAILED) {
                                                                                      s0 = peg$parseSHR();
                                                                                      if (s0 === peg$FAILED) {
                                                                                        s0 = peg$parseST();
                                                                                        if (s0 === peg$FAILED) {
                                                                                          s0 = peg$parseSUB();
                                                                                          if (s0 === peg$FAILED) {
                                                                                            s0 = peg$parseSWAP();
                                                                                            if (s0 === peg$FAILED) {
                                                                                              s0 = peg$parseTEST();
                                                                                              if (s0 === peg$FAILED) {
                                                                                                s0 = peg$parseTRAP();
                                                                                                if (s0 === peg$FAILED) {
                                                                                                  s0 = peg$parseXOR();
                                                                                                  if (s0 === peg$FAILED) {
                                                                                                    s0 = peg$parseIF();
                                                                                                    if (s0 === peg$FAILED) {
                                                                                                      s0 = peg$parseELSE();
                                                                                                      if (s0 === peg$FAILED) {
                                                                                                        s0 = peg$parseDO();
                                                                                                        if (s0 === peg$FAILED) {
                                                                                                          s0 = peg$parseWHILE();
                                                                                                          if (s0 === peg$FAILED) {
                                                                                                            s0 = peg$parseBREAK();
                                                                                                            if (s0 === peg$FAILED) {
                                                                                                              s0 = peg$parseCONTINUE();
                                                                                                              if (s0 === peg$FAILED) {
                                                                                                                s0 = peg$parseWAIT();
                                                                                                                if (s0 === peg$FAILED) {
                                                                                                                  s0 = peg$parseHALT();
                                                                                                                  if (s0 === peg$FAILED) {
                                                                                                                    s0 = peg$parseFunction();
                                                                                                                    if (s0 === peg$FAILED) {
                                                                                                                      s0 = peg$parseDirectiveKeywords();
                                                                                                                      if (s0 === peg$FAILED) {
                                                                                                                        s0 = peg$parseFloatingPointKeyword();
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    }
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        }
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseFloatingPointKeyword() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseFCLR();
    if (s0 === peg$FAILED) {
      s0 = peg$parseFLDR();
      if (s0 === peg$FAILED) {
        s0 = peg$parseFLDM();
        if (s0 === peg$FAILED) {
          s0 = peg$parseFLDIM();
          if (s0 === peg$FAILED) {
            s0 = peg$parseFSTR();
            if (s0 === peg$FAILED) {
              s0 = peg$parseFSTM();
              if (s0 === peg$FAILED) {
                s0 = peg$parseFSTIM();
                if (s0 === peg$FAILED) {
                  s0 = peg$parseFADD();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseFSUB();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parseFCMP();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parseFMUL();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parseFMOD();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parseFDIV();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parseFPOW();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseFSQRT();
                                if (s0 === peg$FAILED) {
                                  s0 = peg$parseFABS();
                                  if (s0 === peg$FAILED) {
                                    s0 = peg$parseFSIN();
                                    if (s0 === peg$FAILED) {
                                      s0 = peg$parseFCOS();
                                      if (s0 === peg$FAILED) {
                                        s0 = peg$parseFTAN();
                                        if (s0 === peg$FAILED) {
                                          s0 = peg$parseFASIN();
                                          if (s0 === peg$FAILED) {
                                            s0 = peg$parseFACOS();
                                            if (s0 === peg$FAILED) {
                                              s0 = peg$parseFATAN();
                                              if (s0 === peg$FAILED) {
                                                s0 = peg$parseFNEG();
                                                if (s0 === peg$FAILED) {
                                                  s0 = peg$parseFEXC();
                                                  if (s0 === peg$FAILED) {
                                                    s0 = peg$parseFINT();
                                                    if (s0 === peg$FAILED) {
                                                      s0 = peg$parseFISNAN();
                                                      if (s0 === peg$FAILED) {
                                                        s0 = peg$parseFISINF();
                                                        if (s0 === peg$FAILED) {
                                                          s0 = peg$parseFLOG2();
                                                          if (s0 === peg$FAILED) {
                                                            s0 = peg$parseFLOG10();
                                                            if (s0 === peg$FAILED) {
                                                              s0 = peg$parseFLD0();
                                                              if (s0 === peg$FAILED) {
                                                                s0 = peg$parseFLD1();
                                                                if (s0 === peg$FAILED) {
                                                                  s0 = peg$parseFLDE();
                                                                  if (s0 === peg$FAILED) {
                                                                    s0 = peg$parseFLDPI();
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseFunction() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseADDRBANK();
    if (s0 === peg$FAILED) {
      s0 = peg$parseADDRBOFS();
      if (s0 === peg$FAILED) {
        s0 = peg$parseADDRPAGE();
        if (s0 === peg$FAILED) {
          s0 = peg$parseADDRPOFS();
          if (s0 === peg$FAILED) {
            s0 = peg$parseASC();
            if (s0 === peg$FAILED) {
              s0 = peg$parseNEXT();
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parserA() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c111) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f140();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserAL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c112) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f141();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserB() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c113) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f142();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserBL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c114) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f143();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c115) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f144();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserCL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c116) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f145();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserD() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c117) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f146();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserDL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c118) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f147();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserX() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c119) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f148();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserXL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c120) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f149();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserY() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c121) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f150();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserYL() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c122) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f151();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserSP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c123) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f152();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserBP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c124) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f153();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserPC() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c125) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parserFLAGS() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 5).toLowerCase() === peg$c126) {
      s1 = input.substr(peg$currPos, 5);
      peg$currPos += 5;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s1 = [s1, s2];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseRegister() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserAL();
    if (s0 === peg$FAILED) {
      s0 = peg$parserA();
      if (s0 === peg$FAILED) {
        s0 = peg$parserBL();
        if (s0 === peg$FAILED) {
          s0 = peg$parserB();
          if (s0 === peg$FAILED) {
            s0 = peg$parserCL();
            if (s0 === peg$FAILED) {
              s0 = peg$parserC();
              if (s0 === peg$FAILED) {
                s0 = peg$parserDL();
                if (s0 === peg$FAILED) {
                  s0 = peg$parserD();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parserXL();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parserX();
                      if (s0 === peg$FAILED) {
                        s0 = peg$parserYL();
                        if (s0 === peg$FAILED) {
                          s0 = peg$parserY();
                          if (s0 === peg$FAILED) {
                            s0 = peg$parserSP();
                            if (s0 === peg$FAILED) {
                              s0 = peg$parserBP();
                              if (s0 === peg$FAILED) {
                                s0 = peg$parseMacroExpansion();
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseRegister16() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserA();
    if (s0 === peg$FAILED) {
      s0 = peg$parserB();
      if (s0 === peg$FAILED) {
        s0 = peg$parserC();
        if (s0 === peg$FAILED) {
          s0 = peg$parserD();
          if (s0 === peg$FAILED) {
            s0 = peg$parserX();
            if (s0 === peg$FAILED) {
              s0 = peg$parserY();
              if (s0 === peg$FAILED) {
                s0 = peg$parserSP();
                if (s0 === peg$FAILED) {
                  s0 = peg$parserBP();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseMacroExpansion();
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseRegister8() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserAL();
    if (s0 === peg$FAILED) {
      s0 = peg$parserBL();
      if (s0 === peg$FAILED) {
        s0 = peg$parserCL();
        if (s0 === peg$FAILED) {
          s0 = peg$parserDL();
          if (s0 === peg$FAILED) {
            s0 = peg$parserXL();
            if (s0 === peg$FAILED) {
              s0 = peg$parserYL();
              if (s0 === peg$FAILED) {
                s0 = peg$parseMacroExpansion();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseGeneralRegister() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserAL();
    if (s0 === peg$FAILED) {
      s0 = peg$parserA();
      if (s0 === peg$FAILED) {
        s0 = peg$parserBL();
        if (s0 === peg$FAILED) {
          s0 = peg$parserB();
          if (s0 === peg$FAILED) {
            s0 = peg$parserCL();
            if (s0 === peg$FAILED) {
              s0 = peg$parserC();
              if (s0 === peg$FAILED) {
                s0 = peg$parserDL();
                if (s0 === peg$FAILED) {
                  s0 = peg$parserD();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parseMacroExpansion();
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseMemoryRegister() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserD();
    if (s0 === peg$FAILED) {
      s0 = peg$parserBP();
      if (s0 === peg$FAILED) {
        s0 = peg$parseMacroExpansion();
      }
    }

    return s0;
  }

  function peg$parseNonGeneralRegister() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parserXL();
    if (s0 === peg$FAILED) {
      s0 = peg$parserX();
      if (s0 === peg$FAILED) {
        s0 = peg$parserYL();
        if (s0 === peg$FAILED) {
          s0 = peg$parserY();
          if (s0 === peg$FAILED) {
            s0 = peg$parserSP();
            if (s0 === peg$FAILED) {
              s0 = peg$parserBP();
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsefZERO() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c127) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c128) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f154();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefCARRY() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c115) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c129) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f155();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefOVERFLOW() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c130) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c131) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f156();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNEGATIVE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c133) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f157();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefEXCEPTION() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c119) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 6).toLowerCase() === peg$c134) {
        s1 = input.substr(peg$currPos, 6);
        peg$currPos += 6;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f158();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 2).toLowerCase() === peg$c135) {
        s1 = input.substr(peg$currPos, 2);
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 === peg$FAILED) {
        if (input.substr(peg$currPos, 7).toLowerCase() === peg$c136) {
          s1 = input.substr(peg$currPos, 7);
          peg$currPos += 7;
        } else {
          s1 = peg$FAILED;
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = peg$currPos;
        peg$begin();
        s3 = peg$parseIdentifierPart();
        peg$end(true);
        if (s3 === peg$FAILED) {
          s2 = undefined;
        } else {
          peg$currPos = s2;
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f158();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsefINTERRUPT_DISABLE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c137) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c138) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f159();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefINTERRUPT_SERVICE() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c139) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c140) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f160();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefSINGLE_STEP() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2).toLowerCase() === peg$c141) {
      s1 = input.substr(peg$currPos, 2);
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 7).toLowerCase() === peg$c142) {
        s1 = input.substr(peg$currPos, 7);
        peg$currPos += 7;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$currPos;
      peg$begin();
      s3 = peg$parseIdentifierPart();
      peg$end(true);
      if (s3 === peg$FAILED) {
        s2 = undefined;
      } else {
        peg$currPos = s2;
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f161();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFlags() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parsefZERO();
    if (s0 === peg$FAILED) {
      s0 = peg$parsefCARRY();
      if (s0 === peg$FAILED) {
        s0 = peg$parsefOVERFLOW();
        if (s0 === peg$FAILED) {
          s0 = peg$parsefNEGATIVE();
          if (s0 === peg$FAILED) {
            s0 = peg$parsefEXCEPTION();
            if (s0 === peg$FAILED) {
              s0 = peg$parsefINTERRUPT_DISABLE();
              if (s0 === peg$FAILED) {
                s0 = peg$parsefINTERRUPT_SERVICE();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsefSINGLE_STEP();
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsefNOTZERO() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefZERO();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTCARRY() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefCARRY();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTOVERFLOW() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefOVERFLOW();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTNEGATIVE() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefNEGATIVE();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTEXCEPTION() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefEXCEPTION();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTINTERRUPT_DISABLE() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefINTERRUPT_DISABLE();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTINTERRUPT_SERVICE() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefINTERRUPT_SERVICE();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsefNOTSINGLE_STEP() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 1).toLowerCase() === peg$c132) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 33) {
        s1 = peg$c143;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parsefSINGLE_STEP();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f162(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNotFlags() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parsefNOTZERO();
    if (s0 === peg$FAILED) {
      s0 = peg$parsefNOTCARRY();
      if (s0 === peg$FAILED) {
        s0 = peg$parsefNOTOVERFLOW();
        if (s0 === peg$FAILED) {
          s0 = peg$parsefNOTNEGATIVE();
          if (s0 === peg$FAILED) {
            s0 = peg$parsefNOTEXCEPTION();
            if (s0 === peg$FAILED) {
              s0 = peg$parsefNOTINTERRUPT_DISABLE();
              if (s0 === peg$FAILED) {
                s0 = peg$parsefNOTINTERRUPT_SERVICE();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsefNOTSINGLE_STEP();
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseAllFlags() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseNotFlags();
    if (s0 === peg$FAILED) {
      s0 = peg$parseFlags();
      if (s0 === peg$FAILED) {
        s0 = peg$parseMacroExpansion();
      }
    }

    return s0;
  }

  function peg$parsecLTE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c144) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c145) {
        s1 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f163();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecLT() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c146) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c147) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f164();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecGTE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c148) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 4).toLowerCase() === peg$c149) {
        s1 = input.substr(peg$currPos, 4);
        peg$currPos += 4;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f165();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecGT() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c150) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c151) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f166();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecABE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c152) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c153) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f167();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecABV() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c154) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c155) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f168();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecBLE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c156) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c157) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f169();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecBLO() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c158) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 === peg$FAILED) {
      if (input.substr(peg$currPos, 5).toLowerCase() === peg$c159) {
        s1 = input.substr(peg$currPos, 5);
        peg$currPos += 5;
      } else {
        s1 = peg$FAILED;
      }
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f170();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecEQ() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3).toLowerCase() === peg$c160) {
      s1 = input.substr(peg$currPos, 3);
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f171();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsecNEQ() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 4).toLowerCase() === peg$c161) {
      s1 = input.substr(peg$currPos, 4);
      peg$currPos += 4;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f172();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseConditionals() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parsecLTE();
    if (s0 === peg$FAILED) {
      s0 = peg$parsecLT();
      if (s0 === peg$FAILED) {
        s0 = peg$parsecGTE();
        if (s0 === peg$FAILED) {
          s0 = peg$parsecGT();
          if (s0 === peg$FAILED) {
            s0 = peg$parsecABE();
            if (s0 === peg$FAILED) {
              s0 = peg$parsecABV();
              if (s0 === peg$FAILED) {
                s0 = peg$parsecBLE();
                if (s0 === peg$FAILED) {
                  s0 = peg$parsecBLO();
                  if (s0 === peg$FAILED) {
                    s0 = peg$parsecEQ();
                    if (s0 === peg$FAILED) {
                      s0 = peg$parsecNEQ();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseAllConditionals() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseConditionals();
    if (s0 === peg$FAILED) {
      s0 = peg$parseMacroExpansion();
    }

    return s0;
  }

  function peg$parseConditionalOrFlag() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseConditionals();
    if (s0 === peg$FAILED) {
      s0 = peg$parseFlags();
      if (s0 === peg$FAILED) {
        s0 = peg$parseMacroExpansion();
      }
    }

    return s0;
  }

  function peg$parseImmediate4() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOrExpression();

    return s0;
  }

  function peg$parseImmediate8() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOrExpression();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f173(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseImmediate16() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOrExpression();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f173(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRelative8() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOrExpression();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f173(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRelative16() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOrExpression();
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f173(s1);
    }
    s0 = s1;

    return s0;
  }

  function peg$parseIndexByX() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCOMMA();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parserX();
      if (s3 !== peg$FAILED) {
        s1 = [s1, s2, s3];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIndexByY() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseCOMMA();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parserY();
      if (s3 !== peg$FAILED) {
        s1 = [s1, s2, s3];
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseImmediateOffset() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parsePLUS();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseOrExpression();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f174(s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAbsolute18() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLBRACKET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseOrExpression();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseIndexByX();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseIndexByY();
        if (s7 === peg$FAILED) {
          s7 = null;
        }
        s8 = peg$parse_();
        s9 = peg$parseRBRACKET();
        if (s9 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f175(s3, s5, s7);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIndirect18() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLANGLE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseOrExpression();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseIndexByX();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseRANGLE();
        if (s7 !== peg$FAILED) {
          s8 = peg$parse_();
          s9 = peg$parseIndexByY();
          if (s9 === peg$FAILED) {
            s9 = null;
          }
          peg$savedPos = s0;
          s0 = peg$f176(s3, s5, s9);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAbsoluteRegister() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLBRACKET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseMemoryRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseImmediateOffset();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseIndexByX();
        if (s7 === peg$FAILED) {
          s7 = null;
        }
        s8 = peg$parse_();
        s9 = peg$parseIndexByY();
        if (s9 === peg$FAILED) {
          s9 = null;
        }
        s10 = peg$parse_();
        s11 = peg$parseRBRACKET();
        if (s11 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f177(s3, s5, s7, s9);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIndirectRegister() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLANGLE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseMemoryRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseImmediateOffset();
        if (s5 === peg$FAILED) {
          s5 = null;
        }
        s6 = peg$parse_();
        s7 = peg$parseIndexByX();
        if (s7 === peg$FAILED) {
          s7 = null;
        }
        s8 = peg$parse_();
        s9 = peg$parseRANGLE();
        if (s9 !== peg$FAILED) {
          s10 = peg$parse_();
          s11 = peg$parseIndexByY();
          if (s11 === peg$FAILED) {
            s11 = null;
          }
          peg$savedPos = s0;
          s0 = peg$f178(s3, s5, s7, s11);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMemoryAddressingMode() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseMacroExpansion();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAbsoluteRegister();
      if (s0 === peg$FAILED) {
        s0 = peg$parseIndirectRegister();
        if (s0 === peg$FAILED) {
          s0 = peg$parseImmediate16();
          if (s0 === peg$FAILED) {
            s0 = peg$parseImmediate8();
            if (s0 === peg$FAILED) {
              s0 = peg$parseAbsolute18();
              if (s0 === peg$FAILED) {
                s0 = peg$parseIndirect18();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseBranchAddressingMode() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseMacroExpansion();
    if (s0 === peg$FAILED) {
      s0 = peg$parseAbsoluteRegister();
      if (s0 === peg$FAILED) {
        s0 = peg$parseIndirectRegister();
        if (s0 === peg$FAILED) {
          s0 = peg$parseRelative16();
          if (s0 === peg$FAILED) {
            s0 = peg$parseRelative8();
            if (s0 === peg$FAILED) {
              s0 = peg$parseAbsolute18();
              if (s0 === peg$FAILED) {
                s0 = peg$parseIndirect18();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseFPAbsolute() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLBRACKET();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            s8 = peg$parse_();
            s9 = peg$parseRBRACKET();
            if (s9 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f179(s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFPIndirect() {
    var s0, s1, s2, s3, s4, s5, s6, s7, s8, s9;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLANGLE();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseRegister();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRegister();
          if (s7 !== peg$FAILED) {
            s8 = peg$parse_();
            s9 = peg$parseRANGLE();
            if (s9 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f180(s3, s7);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseFPAddressingMode() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseMacroExpansion();
    if (s0 === peg$FAILED) {
      s0 = peg$parseFPAbsolute();
      if (s0 === peg$FAILED) {
        s0 = peg$parseFPIndirect();
      }
    }

    return s0;
  }

  function peg$parseOP_ADD() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 43) {
      s0 = peg$c162;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_SUB() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 45) {
      s0 = peg$c163;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_MUL() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 42) {
      s0 = peg$c164;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_DIV() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 47) {
      s0 = peg$c165;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_MOD() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 37) {
      s0 = peg$c166;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_SHL() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 2) === peg$c167) {
      s0 = peg$c167;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_SHR() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 2) === peg$c168) {
      s0 = peg$c168;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_AND() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 38) {
      s0 = peg$c169;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_OR() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 124) {
      s0 = peg$c170;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_NOT() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 33) {
      s0 = peg$c143;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_XOR() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.charCodeAt(peg$currPos) === 94) {
      s0 = peg$c171;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseOP_TAKES() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (input.substr(peg$currPos, 2) === peg$c172) {
      s0 = peg$c172;
      peg$currPos += 2;
    } else {
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c173) {
        s0 = peg$c173;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseAdditiveOperator() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOP_ADD();
    if (s0 === peg$FAILED) {
      s0 = peg$parseOP_SUB();
    }

    return s0;
  }

  function peg$parseMultiplicativeOperator() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOP_MUL();
    if (s0 === peg$FAILED) {
      s0 = peg$parseOP_MOD();
      if (s0 === peg$FAILED) {
        s0 = peg$parseOP_DIV();
      }
    }

    return s0;
  }

  function peg$parseLogicalOperator() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOP_AND();
    if (s0 === peg$FAILED) {
      s0 = peg$parseOP_OR();
      if (s0 === peg$FAILED) {
        s0 = peg$parseOP_XOR();
      }
    }

    return s0;
  }

  function peg$parseShiftOperator() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOP_SHL();
    if (s0 === peg$FAILED) {
      s0 = peg$parseOP_SHR();
    }

    return s0;
  }

  function peg$parseOrExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseXorExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseOP_OR();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseXorExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseOP_OR();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseXorExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseXorExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseAndExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseOP_XOR();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseAndExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseOP_XOR();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseAndExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAndExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseShiftExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseOP_AND();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseShiftExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseOP_AND();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseShiftExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseShiftExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseAdditiveExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseShiftOperator();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseAdditiveExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseShiftOperator();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseAdditiveExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseAdditiveExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseMultiplicativeExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseAdditiveOperator();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseMultiplicativeExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseAdditiveOperator();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseMultiplicativeExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseMultiplicativeExpression() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLiteral();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseMultiplicativeOperator();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseLiteral();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseMultiplicativeOperator();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseLiteral();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f181(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNotExpression() {
    var s0, s1, s2, s3, s4;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseOP_NOT();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseOrExpression();
      if (s4 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f182(s2, s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseNegativeExpression() {
    var s0, s1, s2, s3, s4;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parse_();
    s2 = peg$parseOP_SUB();
    if (s2 !== peg$FAILED) {
      s3 = peg$parse_();
      s4 = peg$parseOrExpression();
      if (s4 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f182(s2, s4);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseCommaSepExpressions() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseOrExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseCOMMA();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseOrExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseOrExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f183(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseStringOrConstantExpression() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseOrExpression();
    if (s0 === peg$FAILED) {
      s0 = peg$parseStringLiteral();
    }

    return s0;
  }

  function peg$parseCommaSepStringOrConstantExpressions() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseStringOrConstantExpression();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$parse_();
      s5 = peg$parseCOMMA();
      if (s5 !== peg$FAILED) {
        s6 = peg$parse_();
        s7 = peg$parseStringOrConstantExpression();
        if (s7 !== peg$FAILED) {
          s4 = [s4, s5, s6, s7];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$parse_();
        s5 = peg$parseCOMMA();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseStringOrConstantExpression();
          if (s7 !== peg$FAILED) {
            s4 = [s4, s5, s6, s7];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f183(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseConstantFunction() {
    var s0, s1, s2, s3, s4, s5, s6, s7;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseADDRBANK();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseLPAREN();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseOrExpression();
        if (s5 !== peg$FAILED) {
          s6 = peg$parse_();
          s7 = peg$parseRPAREN();
          if (s7 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f184(s1, s5);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseADDRBOFS();
      if (s1 !== peg$FAILED) {
        s2 = peg$parse_();
        s3 = peg$parseLPAREN();
        if (s3 !== peg$FAILED) {
          s4 = peg$parse_();
          s5 = peg$parseOrExpression();
          if (s5 !== peg$FAILED) {
            s6 = peg$parse_();
            s7 = peg$parseRPAREN();
            if (s7 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f184(s1, s5);
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseADDRPAGE();
        if (s1 !== peg$FAILED) {
          s2 = peg$parse_();
          s3 = peg$parseLPAREN();
          if (s3 !== peg$FAILED) {
            s4 = peg$parse_();
            s5 = peg$parseOrExpression();
            if (s5 !== peg$FAILED) {
              s6 = peg$parse_();
              s7 = peg$parseRPAREN();
              if (s7 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f184(s1, s5);
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseADDRPOFS();
          if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseLPAREN();
            if (s3 !== peg$FAILED) {
              s4 = peg$parse_();
              s5 = peg$parseOrExpression();
              if (s5 !== peg$FAILED) {
                s6 = peg$parse_();
                s7 = peg$parseRPAREN();
                if (s7 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s0 = peg$f184(s1, s5);
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseNEXT();
            if (s1 !== peg$FAILED) {
              s2 = peg$parse_();
              s3 = peg$parseLPAREN();
              if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                s5 = peg$parseOrExpression();
                if (s5 !== peg$FAILED) {
                  s6 = peg$parse_();
                  s7 = peg$parseRPAREN();
                  if (s7 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f184(s1, s5);
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              s1 = peg$parseASC();
              if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseLPAREN();
                if (s3 !== peg$FAILED) {
                  s4 = peg$parse_();
                  s5 = peg$parseStringLiteral();
                  if (s5 !== peg$FAILED) {
                    s6 = peg$parse_();
                    s7 = peg$parseRPAREN();
                    if (s7 !== peg$FAILED) {
                      peg$savedPos = s0;
                      s0 = peg$f184(s1, s5);
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$parseASC();
                if (s1 !== peg$FAILED) {
                  s2 = peg$parse_();
                  s3 = peg$parseLPAREN();
                  if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    s5 = peg$parseOrExpression();
                    if (s5 === peg$FAILED) {
                      s5 = peg$parseRegister();
                      if (s5 === peg$FAILED) {
                        s5 = peg$parseFlags();
                      }
                    }
                    if (s5 !== peg$FAILED) {
                      s6 = peg$parse_();
                      s7 = peg$parseRPAREN();
                      if (s7 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f185(s1, s5);
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseLiteral() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseLPAREN();
    if (s1 !== peg$FAILED) {
      s2 = peg$parse_();
      s3 = peg$parseOrExpression();
      if (s3 !== peg$FAILED) {
        s4 = peg$parse_();
        s5 = peg$parseRPAREN();
        if (s5 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f186(s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$parseNotExpression();
      if (s0 === peg$FAILED) {
        s0 = peg$parseNegativeExpression();
        if (s0 === peg$FAILED) {
          s0 = peg$parseConstantFunction();
          if (s0 === peg$FAILED) {
            s0 = peg$parseInteger();
            if (s0 === peg$FAILED) {
              s0 = peg$parseMacroExpansion();
              if (s0 === peg$FAILED) {
                s0 = peg$parseIdentifier();
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseReservedWord() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$parseKeyword();

    return s0;
  }

  function peg$parseLabel() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIdentifier();
    if (s1 !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 58) {
        s2 = peg$c174;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f187(s1);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseReservedWord();
      if (s1 !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 58) {
          s2 = peg$c174;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f188();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseIdentifier() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$begin();
    s2 = peg$parseReservedWord();
    peg$end(true);
    if (s2 === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = peg$parseIdentifierName();
      if (s2 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f189();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierName() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseIdentifierStart();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseIdentifierPart();
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseIdentifierPart();
      }
      peg$savedPos = s0;
      s0 = peg$f190(s1, s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseIdentifierStart() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (peg$r0.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 95) {
        s0 = peg$c175;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseIdentifierPart() {
    var s0;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    if (peg$r1.test(input.charAt(peg$currPos))) {
      s0 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseInteger() {
    var s0, s1, s2, s3, s4;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c176) {
      s1 = peg$c176;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      if (peg$r2.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
      }
      if (s2 !== peg$FAILED) {
        s3 = [];
        if (peg$r3.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
        }
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          if (peg$r3.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f191(s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 36) {
        s1 = peg$c177;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        if (peg$r2.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          s3 = [];
          if (peg$r3.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
          }
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (peg$r3.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
            }
          }
          peg$savedPos = s0;
          s0 = peg$f191(s2, s3);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 2) === peg$c178) {
          s1 = peg$c178;
          peg$currPos += 2;
        } else {
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          if (peg$r4.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = [];
            if (peg$r5.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
            }
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              if (peg$r5.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
              }
            }
            peg$savedPos = s0;
            s0 = peg$f191(s2, s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 45) {
            s1 = peg$c163;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
          }
          if (s1 === peg$FAILED) {
            s1 = null;
          }
          if (peg$r6.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
          }
          if (s2 !== peg$FAILED) {
            s3 = [];
            if (peg$r7.test(input.charAt(peg$currPos))) {
              s4 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s4 = peg$FAILED;
            }
            while (s4 !== peg$FAILED) {
              s3.push(s4);
              if (peg$r7.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s4 = peg$FAILED;
              }
            }
            peg$savedPos = s0;
            s0 = peg$f192(s1, s2, s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parseStringLiteral() {
    var s0, s1, s2, s3, s4, s5;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = peg$parseDQUOTE();
    if (s1 !== peg$FAILED) {
      s2 = [];
      s3 = peg$currPos;
      s4 = peg$currPos;
      peg$begin();
      s5 = peg$parseDQUOTE();
      peg$end(true);
      if (s5 === peg$FAILED) {
        s4 = undefined;
      } else {
        peg$currPos = s4;
        s4 = peg$FAILED;
      }
      if (s4 !== peg$FAILED) {
        if (input.length > peg$currPos) {
          s5 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s5 = peg$FAILED;
        }
        if (s5 !== peg$FAILED) {
          s4 = [s4, s5];
          s3 = s4;
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      } else {
        peg$currPos = s3;
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$begin();
        s5 = peg$parseDQUOTE();
        peg$end(true);
        if (s5 === peg$FAILED) {
          s4 = undefined;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
      }
      s3 = peg$parseDQUOTE();
      if (s3 !== peg$FAILED) {
        peg$savedPos = s0;
        s0 = peg$f193(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseDQUOTE();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$currPos;
        s4 = peg$currPos;
        peg$begin();
        s5 = peg$parseDQUOTE();
        peg$end(true);
        if (s5 === peg$FAILED) {
          s4 = undefined;
        } else {
          peg$currPos = s4;
          s4 = peg$FAILED;
        }
        if (s4 !== peg$FAILED) {
          if (input.length > peg$currPos) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
          }
          if (s5 !== peg$FAILED) {
            s4 = [s4, s5];
            s3 = s4;
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$currPos;
          s4 = peg$currPos;
          peg$begin();
          s5 = peg$parseDQUOTE();
          peg$end(true);
          if (s5 === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 !== peg$FAILED) {
            if (input.length > peg$currPos) {
              s5 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
            }
            if (s5 !== peg$FAILED) {
              s4 = [s4, s5];
              s3 = s4;
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        }
        peg$savedPos = s0;
        s0 = peg$f194();
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parseMacroExpansion();
      }
    }

    return s0;
  }

  function peg$parseLCURLY() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 123) {
      s1 = peg$c10;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f195();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRCURLY() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 125) {
      s1 = peg$c11;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f195();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLBRACKET() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 91) {
      s1 = peg$c179;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f196();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRBRACKET() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 93) {
      s1 = peg$c180;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f197();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLPAREN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c181;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f198();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRPAREN() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 41) {
      s1 = peg$c182;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f199();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseLANGLE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 60) {
      s1 = peg$c183;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f200();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseRANGLE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 62) {
      s1 = peg$c184;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f201();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseDQUOTE() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c185;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f202();
    }
    s0 = s1;

    return s0;
  }

  function peg$parsePLUS() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 43) {
      s1 = peg$c162;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f203();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseCOMMA() {
    var s0, s1;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 44) {
      s1 = peg$c186;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f204();
    }
    s0 = s1;

    return s0;
  }

  function peg$parse__() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = [];
    if (peg$r8.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$r8.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f205();
    }
    s0 = s1;

    return s0;
  }

  function peg$parseCOMMENT() {
    var s0, s1, s2, s3;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 35) {
      s1 = peg$c187;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      s2 = [];
      if (peg$r9.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
      }
      while (s3 !== peg$FAILED) {
        s2.push(s3);
        if (peg$r9.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
        }
      }
      peg$savedPos = s0;
      s0 = peg$f206(s2);
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parse_() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = [];
    if (peg$r8.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
    }
    while (s2 !== peg$FAILED) {
      s1.push(s2);
      if (peg$r8.test(input.charAt(peg$currPos))) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
      }
    }
    peg$savedPos = s0;
    s1 = peg$f2();
    s0 = s1;

    return s0;
  }

  function peg$parseEOL() {
    var s0, s1, s2;

    var rule$expects = function (expected) {
      if (peg$silentFails === 0) peg$expect(expected);
    }

    s0 = peg$currPos;
    s1 = [];
    if (peg$r10.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
    }
    if (s2 !== peg$FAILED) {
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        if (peg$r10.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$f2();
    }
    s0 = s1;

    return s0;
  }


      const expectedRegister = () => expected("REGISTER");
      const expectedDataRegister = () => expected("DATA_REGISTER");
      const expectedMemoryAddress = () => expected("MEMORY ADDRESS");
      const expectedComma = () => expected("COMMA");
      const expectedExpression = () => expected("EXPRESSION");
      const expectedIdentifier = () => expected("IDENTIFIER");
      const expectedFlag = () => expected("FLAG");
      const expectedQuote = () => expected("QUOTE");
      const expectedStringLiteral = () => expected("STRING");

      const REGISTERS = {
          A: 0,
          AL: 1,
          B: 2,
          BL: 3,
          C: 4,
          CL: 5,
          D: 6,
          DL: 7,
          X: 8,
          XL: 9,
          Y: 10,
          YL: 11,
          BP: 12,
          SP: 14
      };

      const FLAGS = {
          ZERO: 0,
          OVERFLOW: 1,
          CARRY: 2,
          NEGATIVE: 3,
          SINGLE_STEP: 4,
          INTERRUPT_SERVICE: 5,
          INTERRUPT_DISABLE: 6,
          EXCEPTION: 7,
      };

      const OPCODES = {
          NOP: "nop",
          ADD: "add",
          AND: "and",
          CMP: "cmp",
          SUB: "sub",
          OR:  "or",
          TEST:"test",
          XOR: "xor",
          TRAP:"trap",
          NOT: "not",
          NEG: "neg",
          EXC: "exc",
          SHL: "shl",
          SHR: "shr",
          SWAP: "swap",
          MOV: "mov",
          LD: "ld",
          ST: "st",
          INC: "inc",
          IN: "in",
          OUT: "out",
          ENTER: "enter",
          EXIT: "exit",
          BRK: "brk",
          LOOP: "loop", LOOPS: "loops",
          BR: "br", BRS: "brs",
          CALL: "call", CALLS: "calls",
          PUSHALL: "pushall",
          POPALL: "popall",
          PUSHF: "pushf",
          POPF: "popf",
          PUSH: "push",
          POPMM: "popmm",
          PUSHMM: "pushmm",
          POP: "pop",
          RET: "ret",
          MUL: "mul",
          MOD: "mod",
          DIV: "div",
          SMUL: "smul",
          SMOD: "smod",
          SDIV: "sdiv",
          SET: "set",
          CLR: "clr",
          DEC: "dec",
          HALT: "halt",
          WAIT: "wait",
          FCLR:   "fclr",
          FLDR:   "fldr",
          FLDM:   "fldm",
          FLDIM:  "fldim",
          FSTR:   "fstr",
          FSTM:   "fstm",
          FSTIM:  "fstim",
          FADD:   "fadd",
          FSUB:   "fsub",
          FCMP:   "fcmp",
          FMUL:   "fmul",
          FDIV:   "fdiv",
          FMOD:   "fmod",
          FPOW:   "fpow",
          FSQRT:  "fsqrt",
          FABS:   "fabs",
          FSIN:   "fsin",
          FCOS:   "fcos",
          FTAN:   "ftan",
          FASIN:  "fasin",
          FACOS:  "facos",
          FATAN:  "fatan",
          FNEG:   "fneg",
          FEXC:   "fexc",
          FINT:   "fint",
          FISNAN: "fisnan",
          FISINF: "fisinf",
          FLOG2:  "flog2",
          FLOG10: "flog10",
          FLD0:   "fld0",
          FLD1:   "fld1",
          FLDE:   "flde",
          FLDPI:  "fldpi",
      };

      const DIRECTIVES = {
          SEGMENT: ".segment",
          BYTE: ".byte",
          WORD: ".word",
          STRING: ".string",
          APPEND: ".append"
      };

      function toNumber(inStr) {
          // allow _ as separator
          let str = inStr.replace(/\_/g, "");

          // set up radix based on prefix/postfix
          let radix = 10;
          if (str.startsWith("0x") ||
              str.startsWith("$") ||
              str.endsWith("h")) {
              radix = 16;
          }
          if (str.startsWith("0b") ||
              str.endsWith("b")) {
              radix = 2;
          }

          str = str.replace("0x", "")
                  .replace("0b", "")
                  .replace("$", "")
                  .replace("h", "");

          return parseInt(str, radix);
      }

      const TOKENS = {
          COMMENT: "comment",
          STRING: "string",
          INTEGER: "integer",
          IDENTIFIER: "identifier",
          REGISTER: "register",
          FLAG: "flag",
          EXPRESSION: "expression",
          BINARY_EXPRESSION: "binary.expression",
          UNARY_EXPRESSION: "unary.expression",
          INSTRUCTION: "instruction",
          SEGMENT_DIRECTIVE: "directive.segment",
          NAMESPACE_DIRECTIVE: "directive.namespace",
          CONST_DIRECTIVE: "directive.const",
          IMPORT_DIRECTIVE: "directive.import",
          BYTE_DIRECTIVE: "directive.byte",
          WORD_DIRECTIVE: "directive.word",
          STRING_DIRECTIVE: "directive.string",
          LABEL: "label",
          MEMORY: "memory",
          BLOCK: "block",
          FUNCTION: "function",
          MACRO_DIRECTIVE: "directive.macro",
          MACRO_EXPANSION: "expand.macro",
          CONDITIONAL: "conditional"
      };

      function tBlock(block) {
          return {
              type: TOKENS.BLOCK,
              block,
              pos: location().start
          };
      }

      function tLiteral(value) {
          return {
              type: typeof value === "string" ? TOKENS.STRING: TOKENS.INTEGER,
              value,
              pos: location().start
          };
      }

      function tIdentifier(ident) {
          return {
              type: TOKENS.IDENTIFIER,
              ident,
              pos: location().start
          };
      }

      function tRegister(idx) {
          return {
              type: TOKENS.REGISTER,
              idx,
              size: 2 - (idx & 0b1),
              pos: location().start
          };
      }

      function tBinaryExpression(head, tail) {
          return tail.reduce(function(result, element) {
              return {
                  type: TOKENS.BINARY_EXPRESSION,
                  op: element[1],
                  l: result,
                  r: element[3],
              pos: location().start
              };
          }, head);
      }

      function tUnaryExpression(op, v) {
          return {
              type: TOKENS.UNARY_EXPRESSION,
              op,
              r: v,
              pos: location().start
          };
      }
      
      function tFunction(fn, param) {
          return {
              type: TOKENS.FUNCTION,
              fn,
              param,
              pos: location().start
          };
      }

      function tConditional(conditional) {
          return {
              type: TOKENS.CONDITIONAL,
              conditional
          };
      }

      const MODES = {
          IMMEDIATE: 0b00,
          ABSOLUTE: 0b01,
          BP: 0b10,
          D: 0b11
      };

      function tInstruction(op, {dest, source, m, i, x, y, a, imm, reg, addr, flag, bankReg, offsReg, condition} = {}) {
          return {
              type: TOKENS.INSTRUCTION,
              op,
              dest,
              source,
              m,
              i,
              x,
              y,
              a,
              imm,
              reg,
              addr,
              flag,
              bankReg,
              offsReg,
              pos: location().start,
              condition
          };
      }

      function tNamespace(name, block) {
          return {
              type: TOKENS.NAMESPACE_DIRECTIVE,
              name,
              block,
              pos: location().start
          };
      }

      function tConstant(name, expr) {
          return {
              type: TOKENS.CONST_DIRECTIVE,
              name,
              value: expr,
              pos: location().start
          };
      }

      function tSegment(name, addr, append, block) {
          return {
              type: TOKENS.SEGMENT_DIRECTIVE,
              name,
              addr,
              append,
              block,
              pos: location().start
          };
      }

      function tImport(path) {
          return {
              type: TOKENS.IMPORT_DIRECTIVE,
              path,
              pos: location().start
          };
      }

      function tDataDirective(which, data, size = -1) {
          return {
              type: which,
              data,
              size,
              pos: location().start
          };
      }

      function tComment(comment) {
          return {
              type: TOKENS.COMMENT,
              comment,
              pos: location().start
          };
      }

      function tLabel(name) {
          return {
              type: TOKENS.LABEL,
              name,
              pos: location().start
          };
      }

      function tFlag(flag, neg) {
          return {
              type: TOKENS.FLAG,
              flag,
              neg,
              pos: location().start
          };
      }

      function addressingMode({addr, m, x, y, i}, srce = {}) {
          return Object.assign({}, srce, {
              type: TOKENS.MEMORY,
              addr,
              m,
              x,
              y,
              i,
              pos: location().start
          });
      }
      
      function tMacroDirective(name, params, ast) {
      	return {
          	type: TOKENS.MACRO_DIRECTIVE,
              name,
              params,
              ast
          };
      }
      
      function tMacroExpansion(name, args) {
      	return {
          	type: TOKENS.MACRO_EXPANSION,
              name,
              args
          };
      }

      const hlScopes = [];
      function newScope() {
          hlScopes.unshift({});
      } 
      function exitScope() {
          hlScopes.shift(); 
      }

      const uniqNames = {};
      function uniqIdent(ident) {
          if (!uniqIdent[ident]) {
              uniqIdent[ident] = [];
          }
          if (!hlScopes[0][ident]) {
              hlScopes[0][ident] = [];
          }
          const newIdent = `${ident}__${uniqIdent[ident].length.toString(16).padStart(8, "0")}`;
          uniqIdent[ident].unshift(newIdent);
          hlScopes[0][ident].unshift(newIdent);

          return newIdent;
      }

      function getIdentInScope(ident) {
          if (hlScopes.length < 1) {
              error(`No current scope exists to perform rewrite; couldn't find ${ident}`);
          }
          for (let i = 0; i < hlScopes.length; i++) {
              const curScope = hlScopes[0];
              const curIdents = curScope[ident];
              if (curIdents) {
                  return curIdents[0];
              }
          }
          return ident;
          error(`Rewrite failed; couldn't find ${ident} in scope.`);
      }

      function rewriteIdents(ast) {
          if (!ast) return;

          if (ast.type === TOKENS.IDENTIFIER) {
              if (ast.ident.startsWith("__")) {
                  ast.ident = getIdentInScope(ast.ident);
              }
              return ast;
          }

          if (Array.isArray(ast)) {
              for (let i = 0; i < ast.length; i++) {
                  rewriteIdents(ast[i]);
              }
              return ast;
          }

          if (typeof ast === "object") {
              if (ast && ast.type) {
                  for (let k of Object.keys(ast)) {
                      if (typeof ast[k] === "object") {
                          rewriteIdents(ast[k]);
                      }
                  }
              }
              return ast;
          }

          return ast;
      }


  peg$begin();
  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$expect(peg$endExpectation());
    }

    throw peg$buildError();
  }
}

export {
  peg$SyntaxError as SyntaxError,
  peg$parse as parse
};

export default {
  SyntaxError: peg$SyntaxError,
  parse: peg$parse
};
