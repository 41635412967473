import React from 'react';
export const pages = {
    ["./programming-retroputer/asm/assembler-error-messages.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/assembler-error-messages.md")),
    ["./programming-retroputer/asm/directives.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/directives.md")),
    ["./programming-retroputer/asm/README.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/README.md")),
    ["./programming-retroputer/asm/accessing-memory.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/accessing-memory.md")),
    ["./programming-retroputer/asm/numbers.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/numbers.md")),
    ["./programming-retroputer/asm/identifiers.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/identifiers.md")),
    ["./programming-retroputer/asm/instructions.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/instructions.md")),
    ["./programming-retroputer/asm/subroutine-calling-convention.md".substr(2)]: React.lazy(() => import("./programming-retroputer/asm/subroutine-calling-convention.md")),
    ["./programming-retroputer/what-is-the-kernel/standard-library.md".substr(2)]: React.lazy(() => import("./programming-retroputer/what-is-the-kernel/standard-library.md")),
    ["./programming-retroputer/what-is-the-kernel/README.md".substr(2)]: React.lazy(() => import("./programming-retroputer/what-is-the-kernel/README.md")),
    ["./programming-retroputer/what-is-the-kernel/screen-routines.md".substr(2)]: React.lazy(() => import("./programming-retroputer/what-is-the-kernel/screen-routines.md")),
    ["./programming-retroputer/basic/built-in-function-reference.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/built-in-function-reference.md")),
    ["./programming-retroputer/basic/README.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/README.md")),
    ["./programming-retroputer/basic/statement-reference/print.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/statement-reference/print.md")),
    ["./programming-retroputer/basic/statement-reference/new.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/statement-reference/new.md")),
    ["./programming-retroputer/basic/statement-reference/README.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/statement-reference/README.md")),
    ["./programming-retroputer/basic/statement-reference/def.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/statement-reference/def.md")),
    ["./programming-retroputer/basic/technical-details/README.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/technical-details/README.md")),
    ["./programming-retroputer/basic/technical-details/tokenization.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/technical-details/tokenization.md")),
    ["./programming-retroputer/basic/technical-details/memory-model.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/technical-details/memory-model.md")),
    ["./programming-retroputer/basic/technical-details/tokens.md".substr(2)]: React.lazy(() => import("./programming-retroputer/basic/technical-details/tokens.md")),
    ["./programming-retroputer/the-monitor.md".substr(2)]: React.lazy(() => import("./programming-retroputer/the-monitor.md")),
    ["./SUMMARY.md".substr(2)]: React.lazy(() => import("./SUMMARY.md")),
    ["./README.md".substr(2)]: React.lazy(() => import("./README.md")),
    ["./assembly-language/assembler-error-messages.md".substr(2)]: React.lazy(() => import("./assembly-language/assembler-error-messages.md")),
    ["./assembly-language/untitled.md".substr(2)]: React.lazy(() => import("./assembly-language/untitled.md")),
    ["./assembly-language/numbers.md".substr(2)]: React.lazy(() => import("./assembly-language/numbers.md")),
    ["./using-the-web-version.md".substr(2)]: React.lazy(() => import("./using-the-web-version.md")),
    ["./technical/devices-1/mouse.md".substr(2)]: React.lazy(() => import("./technical/devices-1/mouse.md")),
    ["./technical/devices-1/debugger.md".substr(2)]: React.lazy(() => import("./technical/devices-1/debugger.md")),
    ["./technical/devices-1/power.md".substr(2)]: React.lazy(() => import("./technical/devices-1/power.md")),
    ["./technical/devices-1/keyboard.md".substr(2)]: React.lazy(() => import("./technical/devices-1/keyboard.md")),
    ["./technical/devices-1/i-o-bus.md".substr(2)]: React.lazy(() => import("./technical/devices-1/i-o-bus.md")),
    ["./technical/devices-1/README.md".substr(2)]: React.lazy(() => import("./technical/devices-1/README.md")),
    ["./technical/devices-1/hardware-timers.md".substr(2)]: React.lazy(() => import("./technical/devices-1/hardware-timers.md")),
    ["./technical/devices-1/console.md".substr(2)]: React.lazy(() => import("./technical/devices-1/console.md")),
    ["./technical/devices-1/dma.md".substr(2)]: React.lazy(() => import("./technical/devices-1/dma.md")),
    ["./technical/devices-1/controllers.md".substr(2)]: React.lazy(() => import("./technical/devices-1/controllers.md")),
    ["./technical/technical-overview/9800-persistent-storage-interface.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/9800-persistent-storage-interface.md")),
    ["./technical/technical-overview/block-diagram.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/block-diagram.md")),
    ["./technical/technical-overview/README.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/README.md")),
    ["./technical/technical-overview/6448-video-generator-1/colors.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/colors.md")),
    ["./technical/technical-overview/6448-video-generator-1/specifications.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/specifications.md")),
    ["./technical/technical-overview/6448-video-generator-1/layers.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/layers.md")),
    ["./technical/technical-overview/6448-video-generator-1/README.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/README.md")),
    ["./technical/technical-overview/6448-video-generator-1/tile-sets.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/tile-sets.md")),
    ["./technical/technical-overview/6448-video-generator-1/hardware-sprites.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/hardware-sprites.md")),
    ["./technical/technical-overview/6448-video-generator-1/palette.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6448-video-generator-1/palette.md")),
    ["./technical/technical-overview/1125-sound-generator.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/1125-sound-generator.md")),
    ["./technical/technical-overview/6516-central-processing-unit/6516-cpu.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/6516-cpu.md")),
    ["./technical/technical-overview/6516-central-processing-unit/isa.mdx".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/isa.mdx")),
    ["./technical/technical-overview/6516-central-processing-unit/README.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/README.md")),
    ["./technical/technical-overview/6516-central-processing-unit/addressing-modes.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/addressing-modes.md")),
    ["./technical/technical-overview/6516-central-processing-unit/flags.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/flags.md")),
    ["./technical/technical-overview/6516-central-processing-unit/system-bus.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/6516-central-processing-unit/system-bus.md")),
    ["./technical/technical-overview/memory-layout.md".substr(2)]: React.lazy(() => import("./technical/technical-overview/memory-layout.md")),
}
